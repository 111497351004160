<template>
  <transition name="modal" id="stock-filter-dialog">
    <DxPopup
      class="vertical-center"
      ref="filterStockPopup"
      id="dx-dialog"
      width="100vw"
      height="100vh"
      title="Filtre"
      :close-on-outside-click="false"
      :show-close-button="true"
      :visible="showPopup"
      :resize-enabled="true"
      :darg-enabled="false"
      @hidden="$emit('hidden')"
    >
      <template #title>
        <div class="filter_title_template">
          <div class="left_side">
            <img
              src="@/assets/images/icons/arrow-back.svg"
              v-if="currentStep > 0"
              @click="goBackOneStep"
            />
            <span v-if="currentStep > 0" @click="goBackOneStep">Înapoi</span>
          </div>
          <div class="right_side">
            <img
              class="close_button"
              src="@/assets/images/icons/dialog-close.svg"
              @click="buttonCloseEvent"
            />
          </div>
        </div>
      </template>

      <template #content>
        <div class="filters_tabs">
          <DxTabPanel
            :data-source="filterSteps"
            :loop="false"
            :selected-index="currentStep"
            :animation-enabled="true"
            :swipe-enabled="false"
            :show-nav-buttons="false"
            @selection-changed="tabSelectionChanged($event)"
          >
            <template #item="{ data: step }">
              <div>
                <div
                  v-if="step == 1"
                  class="filter_container"
                  :class="showApplyButton ? 'filter_container_active' : ''"
                  :use-native="true"
                >
                  <div class="filter_header">
                    Filtre disponibile
                    <a
                      v-if="showApplyButton"
                      href="#"
                      class="filter_header_reset"
                      @click="resetFilters"
                      >Resetează filtre</a
                    >
                  </div>
                  <DxScrollView
                    :use-native="true"
                    class="filter_container_scroll"
                  >
                    <div v-show="step == 1" class="stock_steps">
                      <b-form-checkbox
                        class="stock_checkbox"
                        v-model="filters.has_stock_with_zero"
                        @change="changeHasStockWithZeroFilter"
                        style="padding-left: 24px !important;"
                      >
                        Afișare produse cu stoc 0
                      </b-form-checkbox>

                      <filter-header
                        title="Depozite"
                        :filterOptions="warehouses"
                        :selectedOptions="filters.warehouses"
                        filterTextTemplate1="1 depozit selectată"
                        filterTextTemplate2=" depozite selectate"
                        swipeOption="warehouses"
                        @swipeFilter="swipeFilter"
                      />
                    </div>
                  </DxScrollView>
                </div>

                <!-- Warehouses -->
                <div v-if="step == 2" class="filter_container">
                  <div class="filter_header">{{ currentFilterTitle }}</div>
                  <div v-if="current_filter == 'warehouses'">
                    <b-input-group class="search_filters">
                      <b-input-group-prepend>
                        <img src="@/assets/images/icons/search.svg" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Caută lucrări"
                        v-model="instantFilters.warehouses"
                      ></b-form-input>
                    </b-input-group>
                    <b-form-checkbox
                      class="base_row select_all_link"
                      @change="selectAllWarehouses($event)"
                      v-model="selectAll.warehouses"
                    >
                      Selectează tot
                    </b-form-checkbox>
                    <div class="filter_separator"></div>
                    <DxScrollView class="row_container" :use-native="true">
                      <div>
                        <b-form-checkbox
                          class="checkbox_row"
                          v-for="(warehouse, index) in (warehouses
                            ? warehouses
                            : []
                          ).filter((obj) =>
                            obj.name
                              .toUpperCase()
                              .includes(instantFilters.warehouses.toUpperCase())
                          )"
                          :key="index"
                          v-model="filters.warehouses"
                          :value="warehouse.uuid"
                          @change="changeWarehousesFilters"
                        >
                          {{ warehouse.name }}
                        </b-form-checkbox>
                        <div
                          v-if="showApplyButton"
                          class="row_container_spacer"
                        ></div>
                      </div>
                    </DxScrollView>
                  </div>
                </div>
                <div
                  class="apply_filter_sticky_dialog"
                  v-show="showApplyButton"
                >
                  <b-button
                    class="continue_button"
                    variant="outline-secondary"
                    size="sm"
                    pill
                    @click="applySubfilter"
                    >Aplică</b-button
                  >
                </div>
              </div>
            </template>
          </DxTabPanel>
          <toast-message ref="toastMessage"></toast-message>
        </div>
      </template>
    </DxPopup>
  </transition>
</template>

<script>
import { mapActions } from "vuex";
import { DxPopup, DxPosition } from "devextreme-vue/popup";
import DxTabPanel from "devextreme-vue/tab-panel";
import FilterHeader from "@/components/Mobile/filterHeader.vue";
import { DxScrollView } from "devextreme-vue/scroll-view";
import ToastMessage from "@/components/Mobile/toastMessage.vue";

export default {
  name: "StockFilter",
  props: {
    showPopup: {
      type: Boolean,
      default: false,
    },
    filterData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      filterSteps: [1, 2],
      currentStep: 0,
      currentItem: 1,
      current_filter: "",
      warehouses: [],
      filters_on_open: {
        has_stock_with_zero: false,
        warehouses: [],
      },
      filters: {
        has_stock_with_zero: false,
        warehouses: [],
      },
      instantFilters: {
        warehouses: "",
      },
      selectAll: {
        warehouses: false,
      },
      activeFilters: {
        hasWarehouseFilter: false,
        hasStockWithZeroFilter: false,
      },
    };
  },
  components: {
    DxPopup,
    DxPosition,
    DxTabPanel,
    FilterHeader,
    DxScrollView,
    ToastMessage,
  },
  computed: {
    showApplyButton() {
      if (this.currentStep == 0) {
        return (
          this.activeFilters.hasWarehouseFilter ||
          this.activeFilters.hasStockWithZeroFilter
        );
      }
      if (this.currentStep == 1) {
        switch (this.current_filter) {
          case "warehouses":
            return this.activeFilters.hasWarehouseFilter;
          default:
            return false;
        }
      }
      return false;
    },
    currentFilterTitle() {
      switch (this.current_filter) {
        case "warehouses":
          return "Depozite";
        default:
          return "";
      }
    },
  },
  watch: {
    showPopup(newVal) {
      if (newVal == true) {
        let temp_filters = JSON.parse(JSON.stringify(this.filterData));
        temp_filters.has_stock_with_zero = this.filterData?.has_stock_with_zero == 1 ? true : false;
        temp_filters.warehouses = this.filterData?.warehouse_uuid ? this.filterData.warehouse_uuid.split(",") : [];
        this.filters = JSON.parse(JSON.stringify(temp_filters));
        this.filters_on_open = JSON.parse(JSON.stringify(temp_filters));
        this.currentStep = 0;
        this.instantFilters.warehouses = "";
        this.activeFilters.hasWarehouseFilter = false;
        this.activeFilters.hasStockWithZeroFilter = false;
      }
    },
    currentStep(newVal) {
      if (newVal > 0) {
        switch (this.current_filter) {
          case "warehouse":
            this.changeWarehousesFilters();
            break;
        }
      }
    },
  },
  methods: {
    ...mapActions({
      getWarehouses: "smWarehouses/getSmWarehouses",
    }),
    goBackOneStep() {
      if (this.currentStep > 0) {
        this.currentStep = this.currentStep - 1;
      }
    },
    buttonCloseEvent() {
      this.$emit("hidden");
      this.filters = JSON.parse(JSON.stringify(this.filters_on_open));
      this.activeFilters.hasWarehouseFilter = false;
      this.activeFilters.hasStockWithZeroFilter = false;
      this.instantFilters.warehouses = "";
    },
    tabSelectionChanged(e) {
      this.currentItem = e.addedItems[0];
      this.currentStep = this.filterSteps.indexOf(this.currentItem);
    },
    applySubfilter() {
      this.$emit("applyFilters", this.filters);
    },
    swipeFilter(current_filter) {
      this.current_filter = current_filter;
      this.currentStep = 1;
    },
    selectAllWarehouses(selected) {
      if (selected) {
        this.filters.warehouses = (this.warehouses ? this.warehouses : [])
          .filter((obj) =>
            obj.name
              .toUpperCase()
              .includes(this.instantFilters.warehouses.toUpperCase())
          )
          .map((w) => w.uuid);
      } else {
        this.filters.warehouses = [];
      }
      this.changeWarehousesFilters();
    },
    changeWarehousesFilters() {
      this.activeFilters.hasWarehouseFilter =
        this.filters.warehouses.sort().join() !=
        this.filters_on_open.warehouses.sort().join();
    },
    changeHasStockWithZeroFilter() {
      this.activeFilters.hasStockWithZeroFilter =
        this.filters.has_stock_with_zero !=
        this.filters_on_open.has_stock_with_zero;
    },
    resetFilters() {
      this.filters.has_stock_with_zero = false;
      this.filters.warehouses = [];
      this.$emit("applyFilters", this.filters);
    },
  },
  mounted() {
    this.getWarehouses({}).then((response) => {
      this.warehouses = response.data;
    });
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/stockMobile/stockFilter.scss";
</style>
