<template>
     <DxTabPanel
        :element-attr="tabPanelAttributes"
        :data-source="consumSteps"
        :loop="false"
        :selected-item="currentItem"
        :selected-index="currentStep"
        :animation-enabled="true"
        :swipe-enabled="false"
        :show-nav-buttons="false"
        @selection-changed="tabSelectionChanged($event)"
    >
        <template #item="{ data: step }">
            <div>
                <div v-show="step == 1" class="consum_step consum_option_holder">
                    <div v-for="option in consumOptions" :key="option.code" class="consum_selector_option" @click="consumOptionClick(option)">{{option.title}}</div>
                </div>

                <div v-show="step == 2" class="consum_step_map">
                     <google-map
                        ref="googlemap"
                        :showMap="step == 2"
                        :selection_mode="true"
                        :season_uuid="season_uuid_local"
                        :initMapInMount="true"
                        mapClass="consum_map"
                        @showToastMessage="showToastMessage"
                        @removeSelectedParcel="removeSelectedParcel"
                        @addSelectedParcel="addSelectedParcel"
                        @showMapFilter="showMapFilter"
                    ></google-map>
                </div>
                <div v-show="step == 3" class="consum_step">
                    <search-input :parentClass="'consum_filter_search'" placeholder="Caută produs" @search="searchByText"></search-input>
                    <div class="consum-product-scroll warehouse_list_holder">
                        <stock-list ref="stockList" :filterData="filterData" :selectionMode="true" @productSelected="productSelected" :hideZeroStock="true"></stock-list>
                    </div>
                    <DxScrollView v-if="false" ref="consumProductScroll" class="consum-product-scroll warehouse_list_holder" :use-native="true">
                        <div v-for="wh in stockData" :key="wh.uuid" class="warehouse_item">
                            <div class="warehouse_title">
                                <div class="warehouse_title_image">
                                    <img src="@/assets/images/icons/warehouse_icon.svg"/>
                                </div>
                                <div class="warehouse_title_text">{{wh.warehouse_name}}</div>
                            </div>

                            <div class="product_list_holder">
                                <div v-for="(prod, idx) in wh.products" :key="prod.uuid" class="product_item" :last-item="wh.products.length - 1 == idx" @click="productClick">
                                    <div class="product_item_icon">
                                        <img src="@/assets/images/icons/samanta.svg"/>
                                    </div>
                                    <div class="product_item_name">{{prod.name}}</div>
                                    <div class="product_item_quantity">{{prod.quantity}}{{prod.mu_symbol}}</div>
                                </div>
                            </div>
                        </div>
                    </DxScrollView>
                    <div v-if="false" class="warehouse_list_holder">

                    </div>
                </div>
                <DxScrollView ref="consumInputScroll" v-show="step == 4" class="consum_step transfer-input-scroll" :use-native="true">
                    <div>
                        <div class="consum_input_holder" 
                             :class="[{input_holder_error: $v.consumData.consum_date.$error}]">
                            <div @click="showCalendarSelector">
                                <div class="input_label">Data</div>
                                <div class="input_value">
                                    <span :style="{ color: is_consum_date_locked ? 'gray' : 'inherit' }">{{ consumData.consum_date }}</span>
                                </div>
                            </div>
                            <div v-if="is_date_selector_visible">
                                <FunctionalCalendar
                                    :date-format="'yyyy-mm-dd'"
                                    :is-date-range="false"
                                    v-model="calendarData"
                                    :change-month-function="true"
                                    :change-year-function="true"
                                    :is-date-picker="true"
                                    @choseDay="calendarDayChosen"
                                    @input="onDateSelected"
                                ></FunctionalCalendar>
                            </div>
                        </div>
                        <div class="consum_input_holder" @click="productAndWarehouseClick"
                            :class="[{input_holder_error: $v.consumData.product.uuid.$error}]">
                            <div class="input_label">Produs</div>
                            <div class="input_value">{{consumData.product.name}}</div>
                            <div class="consum_input_second_value">{{availableQuantity}} {{selectedMuSymbol}}</div>
                        </div>
                        <div class="consum_input_holder" >
                            <div class="consum_parcel_select_edit">
                                <div class="consum_parcel_info" @click="parcelsClick()">
                                    <div class="input_label">Parcele</div>
                                    <div class="input_value">{{ getParcelNames }}</div>
                                    <div class="consum_input_second_value">{{ getSelectedParcelCountForInput }}</div>
                                </div>
                                <div class="consum_parcel_edit_icon" @click="parcelsClick(true)">
                                    <i class="fa-regular fa-edit"></i>
                                </div>
                            </div>
                        </div>
                        <div class="consum_input_holder"
                            :class="[{input_holder_error: $v.consumData.quantity.$error}]">
                            <div class="input_label">Cantitate</div>
                            <div class="input_value">
                                <DxNumberBox
                                    placeholder="Introduceți cantitatea"
                                    :format="'#0.## ' + selectedMuSymbol"
                                    v-model="consumData.quantity"
                                    class="global_dx_number_input"
                                    :show-clear-button="false"
                                    value-change-event="keyup"
                                    :min="0"
                                    @value-changed="quantityChanged($event)"
                                /></div>
                                <div class="consum_input_second_value">Cost produs: {{productCost * consumData.quantity}} RON</div>
                        </div>
                        <div class="consum_input_holder" v-if="false">
                            <div class="input_label">Cost productir</div>
                            <div class="input_value">
                                <DxNumberBox
                                    placeholder="Introduceți prețul unitar"
                                    format="#,##0.00 RON"
                                    v-model="consumData.unitPrice"
                                    class="global_dx_number_input"
                                    :show-clear-button="false"
                                /></div>
                        </div>
                        <div class="consum_input_holder" @click="productAndWarehouseClick"
                            :class="[{input_holder_error: $v.consumData.warehouse.uuid.$error}]">
                            <div class="input_label">Depozit</div>
                            <div class="input_value">
                                <div class="input_value">{{consumData.warehouse.name}}</div>
                                <DxSelectBox v-if="false"
                                    v-model="consumData.warehouse.uuid"
                                    :search-enabled="false"
                                    search-mode="contains"
                                    search-expr="name"
                                    class="input_value dx_input_value"
                                    placeholder="Alegeți depozit"
                                    :data-source="warehouses"
                                    display-expr="name"
                                    value-expr="uuid"
                                    :show-clear-button="false"
                                    drop-down-button-template="imageIcon"
                                    >
                                        <template #imageIcon>
                                        <div>
                                            <div class="dx_combo_open_button" v-show="!consumData.warehouse.uuid || consumData.warehouse.uuid == ''">
                                                <img src="@/assets/images/icons/plus_green.svg"
                                                class="custom-selector-icon"
                                            ></div>
                                            <div class="dx_combo_open_button" v-show="consumData.warehouse.uuid && consumData.warehouse.uuid.length > 0">
                                                <img src="@/assets/images/icons/edit.svg"
                                                class="custom-selector-icon"
                                            ></div>
                                        </div>
                                    </template>
                                    <template #item="{data}">
                                        <div class="dx_custom_combo_item">{{data.name}}</div>
                                    </template>
                                    </DxSelectBox>
                            </div>
                        </div>

                        <div class="consum_input_holder"
                            v-if="is_work_generator_enabled"
                            :class="[{input_holder_error: $v.consumData.work_machine.uuid.$error}]">
                            <div class="input_label">Lucrare</div>
                            <div><dx-combo-with-add 
                                ref="comboWorkTypes" 
                                @selectionChanged="workSelected"
                                addNewText="Lucrarea nu există"
                                :comboDataset="workTypes"
                                :isComboDisabled="is_consum_date_locked"
                                placeholder="Alegeți lucrare">
                            </dx-combo-with-add></div>
                        </div>

                        <div class="consum_input_holder"
                            v-if="is_work_generator_enabled"
                            :class="[{input_holder_error: $v.consumData.work.uuid.$error}]">
                            <div class="input_label">Tractor</div>
                            <div><dx-combo-with-add 
                                ref="partnerComboWithAdd" 
                                @selectionChanged="workMachineSelected"
                                addNewText="Tractorul nu există"
                                :comboDataset="workMachines"
                                :isComboDisabled="is_consum_date_locked"
                                placeholder="Alegeți tractor">
                            </dx-combo-with-add></div>
                        </div>

                        <div class="consum_input_holder">
                            <div class="input_label">Observații</div>
                            <div class="input_value">
                                <DxTextArea v-model="consumData.notes" class="global_dx_number_input" />
                            </div>
                        </div>
                        <div class="input-bottom-spacer"></div>
                    </div>
                </DxScrollView>
                <DxScrollView v-show="step == 5" class="consum_step transfer-input-scroll" :use-native="true">
                    <div>
                        <div class="parcel_item_edit_surface" v-for="(layer, index) in selectedLayers
                        ? selectedLayers
                        : []"
                    :key="index">
                            <div>{{layer.name}}</div>
                            <DxNumberBox
                                v-model="layer.surface"
                                class="manual-work-input-right"
                                :show-clear-button="false"
                                format="#0.## ha"
                            />
                        </div>
                    </div>
                </DxScrollView>
                <div class="consum_bottom_sticky" v-show="step == 2 && selectedLayers.length > 0">
                    <div class="sticky_action_button" @click="continueFromMap">Continuă</div>
                </div>
                <div class="save_bottom_sticky_two_line" v-show="step == 4">
                    <div class="sticky_action_button" @click="saveConsum(true)">Salvează și consum nou</div>
                    <div class="sticky_action_button_second" @click="saveConsum(false)">Salvează</div>
                </div>
                <div class="consum_bottom_sticky" v-show="step == 5 && selectedLayers.length > 0">
                    <div class="sticky_action_button" @click="applyParcelChanges">Continuă</div>
                </div>
                <toast-message ref="toastMessage"></toast-message>
            </div>
        </template>
    </DxTabPanel>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import { ConsumTypes, ConsumOptions } from "./consum.js"
import DxTabPanel from "devextreme-vue/tab-panel";
import ToastMessage from "../../../../components/Mobile/toastMessage.vue"
import GoogleMap from "@/components/Map/GoogleMap.vue";
import SearchInput  from "@/components/Mobile/searchInput.vue"
import { DxScrollView } from "devextreme-vue/scroll-view";
import DxNumberBox from "devextreme-vue/number-box";
import DxSelectBox from "devextreme-vue/select-box";
import StockList from '../../partials/stockList.vue'
import { required, helpers } from "vuelidate/src/validators";
import DxTextArea from 'devextreme-vue/text-area';
import { FunctionalCalendar } from "vue-functional-calendar";
import moment from "moment";
import DxComboWithAdd from "@/components/Mobile/DxComboWithAdd/index.vue";

const requiredByParam = (param) => (value) => {
    return helpers.req(value) && value <= param;
};

const requiredWorkByParam = (is_work_generator_enabled, compare_uuid) => (value) => {
    const _compare_res = (is_work_generator_enabled && (compare_uuid != null && compare_uuid != ''));
    return _compare_res ? helpers.req(value) : true;
};

export default {
    name: "Consum",
    props:{
        preselectProduct: {
            type: Object,
            default: null
        },
    },
    data(){
        return {
            preselectDate: true,
            calendarData: {},
            consumSteps: [1,2,3,4,5],
            currentStep: 0,
            currentItem: 1,
            currentConsumOption: {},
            tabPanelAttributes: {
                class: 'consum_tabs'
            },
            selectedLayers: [],
            season_uuid_local: '',
            currentSeason: {},
            stockData: [],
            warehouses: [],
            consumData: {
                product: {
                    uuid: '',
                    name: ''
                },
                parcels: [],
                parcel_names: '',
                quantity: null,
                unitPrice: null,
                warehouse: {
                    uuid: '',
                    name: ''
                },
                notes: '',
                consum_date: null,
                batch_uuid: null,
                work_machine: {
                    uuid: null,
                    name: ''
                },
                work: {
                    uuid: '',
                    name: ''
                }
            },
            filterData:{
                name: '',
            },
            availableQuantity: 0.00,
            selectedMuSymbol: '',
            productCost: 0.00,
            is_date_selector_visible: false,
            is_consum_date_locked: false,
            is_work_generator_enabled: false,
            allow_stock_movement_only_in_legal_entity: false,
            workMachines: [],
            workTypes: [],
        };
    },
     validations() {
        return {
            consumData:{
                product:{
                    uuid:{required}
                } ,
                warehouse:{
                    uuid:{required}
                },
                quantity: {requiredByParam:requiredByParam(this.availableQuantity)},
                consum_date: {required},                
                work: {
                    uuid: {requiredWorkByParam: requiredWorkByParam(this.is_work_generator_enabled, this.consumData.work_machine.uuid)}
                },
                work_machine: {
                    uuid: {requiredWorkByParam: requiredWorkByParam(this.is_work_generator_enabled, this.consumData.work.uuid)}
                },
            }
        };
    },
    components: {
        DxTabPanel,
        ToastMessage,
        GoogleMap,
        SearchInput,
        DxScrollView,
        DxNumberBox,
        DxSelectBox,
        StockList,
        DxTextArea,
        FunctionalCalendar,
        DxComboWithAdd
    },
    computed: {
        ...mapGetters({
            seasons: 'seasons/seasons',
        }),
        setPreselectProduct(product){
            this.preselectProduct = product;
        },
        consumTypes(){
            return ConsumTypes;
        },
        consumOptions(){
            return ConsumOptions;
        },
        getSelectedParcelSurfaceDecimal() {
            return (this.selectedLayers && this.selectedLayers.length > 0 ? this.selectedLayers.map(l => l.surface).reduce((x, y) => { return (parseFloat(x) + parseFloat(y)).toFixed(2);}) : 0);
        },
        getSelectedParcelSurface() {
          return "Ai selectat " + this.getSelectedParcelSurfaceDecimal + " hectare cu ";
        },
        getSelectedParcelCount() {
            return (
                "" +
                this.selectedLayers.length +
                (this.selectedLayers.length == 1 ? " parcelă" : " parcele")
            );
        },
        getSelectedParcelCountForInput() {
            return (
                "" +
                this.selectedLayers.length +
                (this.selectedLayers.length == 1 ? " parcelă selectată" : " parcele selecatte") +
                " (" + this.getSelectedParcelSurfaceDecimal + " ha)"
            );
        },
        getParcelNames(){
            return this.selectedLayers.map(l => l.name).join(', ');
        },
        workMachineName() {
            if (
                this.manualWorkData?.workMachine_UUID &&
                this.manualWorkData?.workMachine_name
            ) {
                return this.manualWorkData.workMachine_name;
            }
            return "Selectează tractor!";
        },
    },
    watch: {
        seasons: {
            handler: function(newVal) {
                this.setCurrentSeason(newVal);
            }
        },
    },
    methods: {
         ...mapActions({
            getStockData: 'smStocks/getSmStocks',
            getDepartments: 'company/getDepartments',
            getSeasons: "seasons/getSeasons",
            getWarehouses: 'smWarehouses/getSmWarehouses',
            createConsum: 'smTransactions/createConsumTransaction',
            getStockProductInfo: 'smStocks/getSmStockProductInfo',
            getGroupByCode: 'settings/getGroupByCode',
            getWorkMachines: "works-work-machines/getWorkMachines",
            getWorks: "works-manager/getWorks",
            getGroupByCode: 'settings/getGroupByCode',
        }),
        tabSelectionChanged(e) {
          this.currentItem = e.addedItems[0];
          this.currentStep = this.consumSteps.indexOf(this.currentItem);
          this.changeAddButtonVisibility();
        },
        changeAddButtonVisibility(){
            //this.$emit('changeAddButtonVisibility', this.currentStep == 1 && this.detailStepCode != this.detailCodes.Warehouse);
        },
         goBack(){
            if(this.currentStep > 0){
                this.currentStep = this.currentStep - 1;
                this.setTitle(this.currentStep == 0);
            }
            else{
                this.$emit('goBackParent');
            }
        },
        setTitle(){
            if(this.currentStep == 0) {
                this.$emit('setTitle', 'Consum', false);
            }
             if(this.currentStep >= 1) {
                if(this.currentStep == 1){
                    this.$emit('setTitle', 'Consum produs', true, this.getSelectedParcelSurface, this.getSelectedParcelCount);
                }
                else{
                    this.$emit('setTitle', 'Consum produs', true);
                }
            }
        },
        consumOptionClick(option){
            if(option.code == this.consumTypes.Parcel){
                this.currentConsumOption = option;
                this.currentStep = this.currentStep + 1;
                this.setTitle();
            }
            else{
                this.$refs.toastMessage.showInfo('Funcția este în curs de dezvoltare!');
            }
        },
        removeSelectedParcel(layer) {
            const index = this.selectedLayers.findIndex(l => l.uuid === layer.uuid);
            if (index > -1) {
                this.selectedLayers.splice(index, 1);
            }
            this.setTitle();
        },
        addSelectedParcel(layer) {
            layer.surface = Number.parseFloat(layer.surface);
            if (!this.selectedLayers.some(l => l.uuid === layer.uuid)) {
                this.selectedLayers.push({ ...layer });
            }
            this.setTitle();
        },
        showMapFilter(){},
        showToastMessage(type, message){
            this.$refs.toastMessage.showToastMessage(type, message);
        },
        continueFromMap(){
            if(this.allow_stock_movement_only_in_legal_entity){
                if(this.selectedLayers.length > 0){
                    const departmentLegalEntityUuid = this.selectedLayers[0].department_legal_entity_uuid;
                    const allSameLegalEntity = this.selectedLayers.every(layer => layer.department_legal_entity_uuid === departmentLegalEntityUuid);

                    if (!allSameLegalEntity) {
                        this.$refs.toastMessage.showError('Nu puteți selecta parcele din mai multe entități legale!');                       
                        return;
                    }
                }

                this.$set(this.filterData, 'department_legal_entity_uuid', this.selectedLayers[0].department_legal_entity_uuid);
            }
            if(this.preselectProduct == null){
                this.currentStep = this.currentStep + 1;
            }
            else{
                this.consumData.product.uuid = this.preselectProduct.product.uuid;
                this.consumData.product.name = this.preselectProduct.product.name;  
                this.consumData.quantity = this.preselectProduct.quantity;
                this.consumData.unitPrice = this.preselectProduct.price_net;
                this.availableQuantity = this.preselectProduct.quantity;
                this.consumData.warehouse.uuid = this.preselectProduct.warehouse.uuid;
                this.consumData.warehouse.name = this.preselectProduct.warehouse.name;
                this.currentStep = this.currentStep + 2;
            }
            this.setTitle();
        },
        applyParcelChanges(){
            this.currentStep = this.currentStep - 1;
            this.setTitle();
        },
        setCurrentSeason(seasons){
            if(seasons.length === 0) return;

            this.currentSeason = seasons[0];
            seasons.forEach((obj) => {
                if(new Date(obj.created_at) > new Date(this.currentSeason.created_at)){
                this.$set(this, 'currentSeason', obj);
                }
            });
            this.season_uuid_local = this.currentSeason.uuid;
        },
        searchByText(searchText){
            this.$set(this.filterData, 'name', searchText);
        },
        productClick(){
            this.currentStep = this.currentStep + 1;
            this.setTitle();
        },
        parcelsClick(edit_surface = false){
            this.currentStep = edit_surface ? this.currentStep + 1 : this.currentStep - 2;
            this.setTitle();
        },
        productSelected(stock_data, warehouse){
            this.consumData.product.uuid = stock_data.uuid;
            this.consumData.product.name = stock_data.name;
            this.consumData.warehouse.uuid = warehouse.warehouse_uuid;
            this.consumData.warehouse.name = warehouse.warehouse_name;
            this.availableQuantity = warehouse.quantity;
            this.selectedMuSymbol = stock_data.measure_unit.symbol;
            this.currentStep = this.currentStep + 1;
            this.setTitle();

        },
        productAndWarehouseClick(){
            this.currentStep = this.currentStep - 1;
            this.setTitle();
        },
        workMachineSelected(selectedWorkMachine){
            if(selectedWorkMachine && selectedWorkMachine.length > 0 && selectedWorkMachine[0]){
                this.consumData.work_machine.uuid = selectedWorkMachine[0].uuid;
                this.consumData.work_machine.name = selectedWorkMachine[0].name;
                this.consumData.work_machine.autopropulsat = selectedWorkMachine[0].autopropulsat;
                this.consumData.work_machine.equipment = selectedWorkMachine[0].equipment;
                this.consumData.work_machine.is_dataller = selectedWorkMachine[0].is_dataller;
            }
        },
        workSelected(selectedWork){
            if(selectedWork && selectedWork.length > 0 && selectedWork[0]){
                this.consumData.work.uuid = selectedWork[0].uuid;
                this.consumData.work.name = selectedWork[0].name;
            }
        },
        saveConsum(saveAndNew){
            this.$v.consumData.$touch();
            if (!this.$v.consumData.$invalid) {
                let consumDataForSave ={
                    department_uuid: this.getWarehouseDepartmentByWarehouseUuid(this.consumData.warehouse.uuid),
                    warehouse_uuid: this.consumData.warehouse.uuid,
                    product_uuid: this.consumData.product.uuid,
                    quantity: this.consumData.quantity,
                    //unit_price: 1,
                    parcels: [],
                    notes: this.consumData.notes,
                    consum_date: this.consumData.consum_date,
                    batch_uuid: this.consumData.batch_uuid,
                    work_machine: this.consumData.batch_uuid ? null : this.consumData.work_machine, /* just one work for one batch */
                    work_uuid: this.consumData.batch_uuid ? null : this.consumData.work.uuid
                };

                this.selectedLayers.forEach(l => {
                    if(l.parcel_uuid && l.parcel_uuid.length > 0){
                        consumDataForSave.parcels.push({
                                uuid: l.parcel_uuid,
                                surface: l.surface,
                                season_uuid: l.season_uuid
                            });
                    }
                });

                this.createConsum(consumDataForSave).then(response => {
                    this.$refs.toastMessage.showSuccess('Consumul a fost salvat cu succes!');
                    if(saveAndNew){
                        this.$set(this.filterData, 'warehouse_uuid', this.consumData.warehouse.uuid);
                        this.resetForm();
                        this.is_consum_date_locked = true;
                        this.consumData.batch_uuid = response.data.batch_uuid ?? null;
                    }
                    else{
                        this.$delete(this.filterData, 'warehouse_uuid');
                        this.is_consum_date_locked = false;
                        this.consumData.batch_uuid = null;
                        this.closeTransactions('Consumul a fost salvat cu succes!');
                    }
                }).catch((e) => {
                    this.$refs.toastMessage.showError('A apărut o erroare la salvarea consumului. Contactați administratorul de sistem!');
                });
            }
            else{
                if(this.$v.consumData.quantity.$invalid){
                    this.$refs.toastMessage.showError("Cantitatea trebuie să fie înre 0 și " + this.availableQuantity + "!");
                } else if(this.is_work_generator_enabled && (this.$v.consumData.work_machine.uuid.$invalid || this.$v.consumData.work.uuid.$invalid)){
                    this.$refs.toastMessage.showError("Trebuie sâ selectați tractor și lucrare!");
                }
            }
        },
        resetForm(){
            this.$v.$reset();
            this.productCost = 0.00;
            const consum_date = this.consumData.consum_date;
            const consum_work_machine = this.consumData.work_machine;
            const consum_work = this.consumData.work;

            this.consumData = {
                product: {
                    uuid: '',
                    name: ''
                },
                parcels: [],
                parcel_names: '',
                quantity: null,
                unitPrice: null,
                warehouse: {
                    uuid: '',
                    name: ''
                },
                notes: '',
                consum_date: null,
                batch_uuid: null,
                work_machine: {
                    uuid: null,
                    name: ''
                },
                work: {
                    uuid: null,
                    name: ''
                }
            };

            this.consumData.consum_date = consum_date;
            this.consumData.work_machine = consum_work_machine;
            this.consumData.work = consum_work;

           this.productAndWarehouseClick();
        },
        closeTransactions(message){
            this.$emit('closeTransactions', message);
        },
        quantityChanged(e){
            if(this.consumData.quantity != null){
                let getStockInfodata = {
                    product_uuid: this.consumData.product.uuid,
                    warehouse_uuid: this.consumData.warehouse.uuid,
                    quantity: this.consumData.quantity
                }
                this.getStockProductInfo(getStockInfodata).then(response => {
                    this.productCost = response.price_net;
                    this.$set(this, 'productCost', response.price_net);
                });
            }
        },
        showCalendarSelector(){
            if(this.is_consum_date_locked && !!this.consumData.consum_date){
                this.is_date_selector_visible = false;
                return;
            }
            this.is_date_selector_visible = !this.is_date_selector_visible;
        },
        calendarDayChosen(data){
            this.is_date_selector_visible = false;
        },
        onDateSelected(date) {
            this.consumData.consum_date = date.selectedDate;
        },
        getWarehouseDepartmentByWarehouseUuid(warehouseUuid){
            const warehouse = this.warehouses.find(w => w.uuid == warehouseUuid);
            if(warehouse){
                return warehouse.department_uuid;
            }
            return null;
        },
    },
    mounted(){
        this.getGroupByCode({
            code: 'STOCK_MANAGEMENT'
        }).then(response => {
            let _setting = response.items.find(obj => obj.code == 'USE_CURRENT_DATE_ON_CREATION');
            this.preselectDate = _setting?.value == '1';
            if(this.preselectDate){
                this.consumData.consum_date = moment().format('YYYY-MM-DD');
            }
        });
        
        this.getSeasons();
        this.setTitle();
         this.getWarehouses({}).then(response => {
            this.warehouses = response.data;
        });

        this.getGroupByCode({
            code: 'STOCK_MANAGEMENT'
        }).then(response => {
            let _setting = response.items.find(obj => obj.code == 'PARCEL_CONSUM_WORK_GENERATOR_ENABLED');
            this.is_work_generator_enabled = false;
            if(_setting !== undefined){
                this.is_work_generator_enabled = _setting?.value == '1';
            }

            let _setting_le = response.items.find(obj => obj.code == 'ALLOW_STOCK_MOVEMENT_ONLY_IN_LEGAL_ENTITY');
            this.allow_stock_movement_only_in_legal_entity = false;
            if(_setting_le !== undefined){
                this.allow_stock_movement_only_in_legal_entity = _setting_le?.value == '1';
            }

            if (this.is_work_generator_enabled) {
                this.getWorkMachines({
                    enablePagination: 0,
                    limit: 9999,
                    autopropulsat: 1,
                }).then((response) => {
                    this.workMachines = response.data;
                });

                this.getWorks({
                    enablePagination: 0,
                    limit: 9999,
                }).then((response) => {
                    this.workTypes = response.data;
                });
            }
        });
    }
}
</script>

<style lang="scss">
  @import "@/assets/scss/stockMobile/consum.scss";
  @import '@fortawesome/fontawesome-free/css/all.css';
</style>
