<template>
  <div class="configurator">
    <card :title="$t('import_configurator')" id="configurator" v-if="canEdit || canDelete">
      <div class="row table-responsive">
        <div class="col-lg-12">
          <table class="table">
            <th class="text-left">{{ $t('import_type') }}</th>
            <th></th>
            <th></th>
            <th></th>
            <tbody>
              <tr v-for="type in types" :key="Math.random()"
                @mouseover="hoveredRow = type.id" @mouseleave="hoveredRow = null">
                <td class="td-text text-left" :title="$t('import_type')" :class="{ 'selection-row': isHovered(type.id) }">
                  {{ $t('tools.configurator.' + lineToUnderscore(type.ref)) }}
                </td>
                <td class="td-text text-center" :class="{ 'selection-row': isHovered(type.id) }">
                  <img :src="require('@/assets/images/desktop/icons/controls/edit.svg')"
                    :class="['edit', 'success-filter']" :title="$t('tools.configurator.edit_templates')"
                    class="action-icon" @click="goToImportTemplatePage(type.id)" />
                </td>
                <td class="td-text text-center" :class="{ 'selection-row': isHovered(type.id) }" >
                    <img v-bind:style="type.id === types.SGPS_DASC_IMPORT.id ? { marginLeft: '40px' } : {}" :src="require('@/assets/images/desktop/icons/controls/cog.svg')"
                    :class="['cog', 'primary-filter']" :title="$t('tools.configurator.settings')" class="action-icon"
                    @click="showSettingsDialog(type)" />
                    <img style="margin-left: 20px;" v-if="type.id === types.SGPS_DASC_IMPORT.id" :src="require('@/assets/images/desktop/icons/controls/queue.svg')"
                    :class="['queue']" :title="$t('tools.statuses.edit_status')"
                    class="action-icon" @click="goToCriterialBuilderPage()" />
                </td>
                <td class="td-text text-center" :class="{ 'selection-row': isHovered(type.id) }">
                  <img :src="require('@/assets/images/desktop/icons/controls/arrow-down.svg')"
                    :class="['uploaded', 'secondary-filter']" :title="$t('import_history')" class="action-icon"
                    @click="goToImportHistoryPage(type)" />
                </td>
              </tr>
            </tbody>
          </table>
          <ImportSettingsDialog :show="showImportSettingsModal" @close="showImportSettingsModal = false" :importTypes="types"
            :data="importSettingsModalData" @onOK="showImportSettingsModal = false"></ImportSettingsDialog>
        </div>
      </div>
    </card>
    <div v-else>
      <forbidden></forbidden>
    </div>
  </div>
</template>

<script>
import { canDelete, canEdit, permissions, permissionTypes } from "@/helpers/Permissions";
import Forbidden from "@/pages/errors/Forbidden";
import { types } from "@/helpers/ImportTypes"
import { lineToUnderscore } from "~/helpers/common";
import ImportSettingsDialog from './partials/ImportSettingsDialog.vue'

export default {
  name: 'Configurator',
  data() {
    return {
      types: [],
      importSettingsModalData: {},
      showImportSettingsModal: false,
      hoveredRow: null,
    }
  },
  components: {
    Forbidden,
    ImportSettingsDialog,
  },
  computed: {
    config() {
      return {
        permission: permissions.CONFIGURATOR,
        permission_type: permissionTypes.module,
      };
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this);
    },
  },
  methods: {
    isHovered(id) {
      return this.hoveredRow === id;
    },
    goToImportTemplatePage(import_type_id) {
      this.$router.push({ path: '/tools/import-configurator/templates', query: { import_type_id: import_type_id } });
    },
    goToCriterialBuilderPage() {
      this.$router.push({ path: '/tools/statuses', query: { type: 'import_validated_data' } });
    },
    goToImportHistoryPage(import_type) {
      this.$router.push({ path: '/tools/import-history', query: { import_type_id: import_type.id, import_typ_ref: import_type.ref } });
    },
    showSettingsDialog(import_type) {
      this.importSettingsModalData.import_id = import_type.id;
      this.importSettingsModalData.import_ref = import_type.ref;
      this.importSettingsModalData.is_active_api_import = import_type.is_active_api_import;
      this.showImportSettingsModal = true;
    },
    lineToUnderscore(value) {
      return lineToUnderscore(value);
    },
  },
  mounted() {
    this.types = types;
  },
}
</script>

<style scoped>
.action-icon {
  width: 22px;
  height: 22px;
  cursor: pointer;
}

.selection-row {
  background-color: #e6e6e3;
}
</style>