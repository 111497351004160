<template>
  <DxDataGrid
    ref="rcgrid"
    :data-source="dataSource"
    :show-borders="true"
    :remote-operations="gridRemoteOperations"
    @init-new-row="initNewFilter($event)"
  >
    <DxColumn
      dataField="field_name"
      :caption="$t('reporting.tabs.filters.field_name')"
      :width="'20%'"
      :filter-operations="['contains']"
    >
      <DxRequiredRule
        :message="$t('reporting.validation.field_name_is_required')"
      />
    </DxColumn>
    <DxColumn
      dataField="title"
      :caption="$t('reporting.tabs.filters.filter_title')"
      :width="'20%'"
      :filter-operations="['contains']"
    >
      <DxRequiredRule :message="$t('reporting.validation.title_is_required')" />
    </DxColumn>
    <DxColumn
      dataField="order"
      dataType="number"
      alignment="center"
      :caption="$t('reporting.tabs.filters.order')"
      :width="'20%'"
      :filter-operations="['=']"
    >
    </DxColumn>
    <DxColumn
      dataField="data_type"
      alignment="center"
      :caption="$t('reporting.tabs.filters.data_type')"
      :width="'20%'"
      :filter-operations="['=']"
    >
      <DxLookup
        :data-source="dataTypeList"
        value-expr="value"
        display-expr="text"
      />
    </DxColumn>
    <DxFilterRow :visible="true" />
    <DxEditing
      :allow-adding="allowEditing()"
      :allow-updating="allowEditing()"
      :allow-deleting="allowDeleting"
      mode="form"
      :use-icons="true"
    >
      <DxTexts
        :confirm-delete-message="$t('reporting.validation.confirm_delete')"
        :confirm-delete-title="$t('confirm_delete_title')"
      />
    </DxEditing>
  </DxDataGrid>
</template>

<script>
import DxDataGrid, {
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxLookup,
  DxRequiredRule,
  DxTexts,
} from "devextreme-vue/data-grid";
import { mapActions } from "vuex";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import { canDelete, canEdit } from "@/helpers/Permissions";

export default {
  props: {
    data: Object,
  },
  components: {
    DxLookup,
    DxFilterRow,
    DxRequiredRule,
    DxTexts,
    DxEditing,
    DxColumn,
    DxDataGrid,
  },
  data() {
    return {
      gridRemoteOperations: {
        filtering: true,
        sorting: true,
      },
    };
  },
  computed: {
    dataSource() {
      return new DataSource({
        store: new CustomStore({
          key: "id",
          load: this.load,
          insert: this.insert,
          update: this.update,
          remove: this.remove,
        }),
      });
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this);
    },
    dataTypeList() {
      return [
        { value: "string", text: "String" },
        { value: "number", text: "Number" },
        { value: "date", text: "Date" },
        { value: "boolean", text: "Boolean" },
        { value: "datetime", text: "Datetime" },
      ];
    },
  },
  methods: {
    ...mapActions({
      get: "reporting/getReportFilters",
      create: "reporting/createReportFilter",
      change: "reporting/updateReportFilter",
      delete: "reporting/deleteReportFilter",
    }),
    async load(data) {
      let dataSource = {
        data: [],
        totalCount: 0,
      };

      await this.get({
        report_id: this.data.id,
        filter: data.filter,
        sort: data.sort,
      }).then((resp) => {
        dataSource.data = resp.data.data;
        dataSource.totalCount = resp.data.pagination.total;
      });

      return dataSource;
    },
    async insert(data) {
      await this.create(
        Object.assign({}, data, {
          report_id: this.data.id,
        })
      );
    },
    async update(key, obj) {
      const updatable = Object.assign(
        {},
        this.dataSource.items().find((item) => item.id === key),
        obj
      );

      await this.change(
        Object.assign({}, updatable, {
          id: key,
          report_id: this.data.id,
        })
      );
    },
    remove(id) {
      this.delete({
        id: id,
      });
    },
    allowEditing(e) {
      return this.canEdit;
    },
    allowDeleting(e) {
      //return this.canDelete && e.row.data.is_deletable === 1;
      return true;
    },
    initNewFilter(e) {
      e.data.data_type = "string";
      e.data.report_id = this.data.id;

      let maxOrder = 0;
      this.dataSource.items().forEach((item) => {
        if (item.order > maxOrder) {
          maxOrder = item.order;
        }
      });
      e.data.order = maxOrder + 1;
    },
  },
};
</script>
