<template>
  <DxSelectBox
    v-model="selectedParcel"
    :value-expr="'uuid'"
    :display-expr="'name'"
    :data-source="parcels"
    :search-enabled="true"
    :show-clear-button="true"
    @value-changed="parcelChanged"
  />
</template>

<script>
import {mapGetters} from 'vuex';
import DxSelectBox from 'devextreme-vue/select-box';

export default {
  name: "ParcelComboBoxComponent",
  props: {
    seasonUuid: {
      type: String,
      required: true
    }
  },
  components: {
    DxSelectBox
  },
  data() {
    return {
      selectedParcel: null,
      parcels: []
    }
  },
  computed: {
    ...mapGetters({
      parcelsList: 'works-parcels/parcels'
    })
  },
  watch: {
    seasonUuid: {
      immediate: true,
      handler(newVal) {
        this.loadParcels(newVal);
      }
    }
  },
  methods: {
    loadParcels(seasonUuid) {
      this.parcels = this.parcelsList.filter(parcel => parcel.season_uuid === seasonUuid);
    },
    parcelChanged(e) {
      this.$emit('parcelSelected', e.value);
    }
  }
};
</script>
