import * as api from '../../api/index'
import * as endpoints from '../../api/endpoints'
import * as types from "../../mutation-types";
import {SET_WORK_MACHINE_EQUIPMENT} from "../../mutation-types";

const actions = {
    getWorkMachines({commit}, payload) {
        return api.fetch(endpoints.WORK_MACHINES, payload, commit,(response) => {
          commit(types.SET_WORK_MACHINES, response.data.data);
          commit("common/"+types.SET_PAGINATION, response.data.pagination, { root: true });
        });
    },
    getWorkMachineEquipments({commit}, payload) {
        return api.fetch(endpoints.WORK_MACHINE_EQUIPMENTS, payload, commit,(response) => {});
    },
    getWorkMachine({commit}, payload) {
      return api.fetch(endpoints.WORK_MACHINES+'/'+payload.uuid, payload, commit,(response) => {
        commit(types.SET_WORK_MACHINE, response.data);
      });
    },
    createWorkMachine({commit}, payload) {
      return api.post(endpoints.WORK_MACHINES, payload, commit,(response) => {

      });
    },
    updateWorkMachine({commit}, payload) {
      return api.put(endpoints.WORK_MACHINES+'/'+payload.uuid, payload, commit,(response) => {
        commit(types.SET_WORK_MACHINE, response.data);
        commit("common/"+types.SET_PAGINATION, response.data.pagination, { root: true });
      });
    },
    shareWorkMachine({commit}, payload) {
      return api.put(endpoints.WORK_MACHINES+'/'+payload.uuid+'/share', payload, commit,(response) => {
        commit(types.SET_WORK_MACHINE, response.data);
        commit(types.SET_WORK_MACHINE_ITEM, response.data);
        commit("common/"+types.SET_PAGINATION, response.data.pagination, { root: true });
      });
    },
    deleteWorkMachine({commit}, payload) {
      return api.remove(endpoints.WORK_MACHINES+'/'+payload.uuid, payload, commit,(response) => {
        commit(types.SET_WORK_MACHINE, response.data);
        commit("common/"+types.SET_PAGINATION, response.data.pagination, { root: true });
      });
    }
}

export default actions;
