<template>
   <div>
    <div class="col-lg-12">
        <DxForm
        ref="form"
        :show-colon-after-label="true"
        :col-count="2"
        :form-data="formData"
        label-location="top"
        label-mode="outside"
        :on-field-data-changed="evt => onFieldChange(evt.dataField, evt.value)"
      >
        <DxFormItem
          v-for="item in basicInfoItems"
          :key="item.dataField"
          :data-field="item.dataField"
          :editor-type="item.editorType"
          :editor-options="{
            stylingMode: 'filled',
            valueChangeEvent: 'input',
            ...item.editorOptions,
          }"
          :col-span="item.colSpan"
        >
          <dx-label
            v-if="item.label"
            :text="item.label"
          />
          <dx-validation-rule
            v-for="rule in item.validators"
            :key="rule"
            :type="rule.type"
          />
        </DxFormItem>
      </DxForm>
    </div>
    <div class="col-lg-12">
        <DxDataGrid 
            :allow-column-reordering="true"
            :ref="reportGridRefKey"
            :data-source="report_dataset"
            :show-borders="false"
            :element-attr="gridAttributes"
            :columns="gridColumns"
            @exporting="onExporting"
            :column-auto-width="true"
        >
            
            <DxPaging :total="report_dataset.length" :page-size="15"/>
            <DxPager
                :visible="true"
                :allowed-page-sizes="pageSizes"
                display-mode="compact"
                :show-page-size-selector="true"
                :show-info="true"
                :show-navigation-buttons="true"
            />
            <DxEditing
                :allow-adding="false"
                :allow-updating="false"
                :allow-deleting="false"
                :use-icons="true"
                mode="row"
            >
                <DxTexts
                    confirm-delete-message="Sigur vreți să ștergeți raportul?",
                    confirm-delete-title="Confirmare ștergere"
                />
            </DxEditing>
            <DxHeaderFilter :visible="true" />
            <DxFilterRow :visible="true" apply-filter="auto"/>
            <DxSelection mode="multiple" />
            <DxColumnChooser :enabled="true"></DxColumnChooser>
            <DxExport
                    :enabled="true"
                    :formats="['xlsx','pdf']"
                    :allow-export-selected-data="true"
                />
            <DxToolbar>
                <DxItem
                    location="before"
                    template="backTemplate"
                />
                <DxItem
                    location="before"
                    name="groupPanel"
                />
                <DxItem
                    location="after"
                    template="resetFiltersTemplate"
                />
                <DxItem
                    location="after"
                    template="refreshTemplate"
                />
                <DxItem
                    location="after"
                    name="exportButton"
                />
                <DxItem
                    location="after"
                    name="columnChooserButton"
                />
                
            </DxToolbar>

            <template #refreshTemplate>
            <DxButton
                icon="refresh"
                @click="refreshDataGrid"
            />
            </template>
            <template #resetFiltersTemplate>
                <DxButton
                icon="clear"
                @click="clearFilterData"
            /> </template> 
            <template #backTemplate>
                <DxButton
                    icon="arrowleft"
                    @click="backToList"
                />
            </template>
            <DxGroupPanel :visible="true"/>
            <DxGrouping :auto-expand-all="true"/>
            <DxScrolling column-rendering-mode="virtual"/>
            <DxSummary>
                <DxTotalItem v-for="item in aggregatedColumns.filter(g => g.show_aggregate_in_footer)"
                    :column="item.column"
                    :summary-type="item.summary_type"
                    :value-format="item.value_format"
                ></DxTotalItem>
                <DxGroupItem v-for="item in aggregatedColumns.filter(g => g.show_aggregate_in_group)"
                    :column="item.column"
                    :summary-type="item.summary_type"
                    :value-format="item.value_format"
                    :show-in-group-footer="true"
                ></DxGroupItem>
            </DxSummary>
        </DxDataGrid>
    </div>
</div>
    
</template>

<script>

import { mapGetters, mapActions } from 'vuex';
import { DxDataGrid, DxColumn, DxPaging, DxPager, DxEditing, DxTexts, DxLookup, DxRequiredRule, 
        DxStringLengthRule, DxHeaderFilter, DxFilterRow, DxSelection, DxColumnChooser, DxExport, 
        DxToolbar, DxItem, DxGrouping, DxScrolling,
        DxGroupPanel, DxSummary, DxTotalItem, DxGroupItem,
        DxSummaryTexts} from 'devextreme-vue/data-grid';
import { DxButton } from 'devextreme-vue/button';
import { jsPDF } from 'jspdf';
import { exportDataGrid as exportDataGridToPdf} from 'devextreme/pdf_exporter';
import { exportDataGrid } from 'devextreme/excel_exporter';
import 'jspdf-autotable';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import {
  DxForm,
  DxItem as DxFormItem,
  DxLabel,
  DxValidationRule,
} from 'devextreme-vue/form';
import moment from 'moment';

export default {
    name: 'ReportGridView',
    props: {
        showModule: {
            type: Boolean,
            default: false,
        },
        templateData: {
            type: Object,
            default: {},
        }
    },
    data(){
        return {
            formData: {},
            basicInfoItems: [
            {
                dataField: "date_from",
                editorType: 'dxDateBox',
                dataType: 'datetime',
                label: 'Date from'
            },
            {
                dataField: "date_to",
                editorType: 'dxDateBox',
                dataType: 'datetime',
                label: 'Date to'
            },
            
            ],
            reportGridRefKey: 'report-grid',
            gridAttributes:{
                class: 'admin-grid'
            },
            selectedReport: {},
            columnEditorOptions: { showClearButton: true, placeholder: "Denumire raport" },
            pageSizes: [15, 25, 100],
            report_dataset: [],
            gridColumns: [
                {
                    dataField: 'department_name',
                    caption: 'Departament',
                    width: '150px'
                },
                {
                    dataField: 'parcel_name',
                    caption: 'Parcela',
                    
                },
                {
                    dataField: 'product_name',
                    caption: 'Product',
                    
                }
            ],
            aggregatedColumns: []
        }
    },
    watch: {
        showModule: {
            handler: function (newVal) {
                
                if(newVal){
                    this.loadReportData();
                }
            },
            deep: true,
        },
    },
    components: {
        DxDataGrid, DxColumn, DxPaging, DxPager, DxEditing, DxTexts, DxLookup, DxRequiredRule, 
        DxStringLengthRule, DxHeaderFilter, DxFilterRow, DxSelection, DxColumnChooser, DxExport, 
        DxButton, DxToolbar, DxItem, DxGrouping, DxScrolling, DxGroupPanel, DxSummary, DxTotalItem,
        DxGroupItem, DxForm, DxFormItem, DxLabel, DxValidationRule
    },
    computed:{
        reportGrid: function() {
            return this.$refs[this.reportGridRefKey].instance;
        },
    },
    methods:{
        ...mapActions({
            getReports: "reporting/getReports",
            getReportsData: "reporting/getReportsData",
            getReportColumns: "reporting/getReportColumns",
            getReportFilters: "reporting/getReportFilters",
        }),
        onFieldChange(fieldName, value){
            this.loadReportData();
        },
        getCurrentDate() {
            const date = new Date();
            const year = date.getFullYear();
            const month = ('0' + (date.getMonth() + 1)).slice(-2); // Hónap 0-tól indul, ezért +1
            const day = ('0' + date.getDate()).slice(-2);
            const hours = ('0' + date.getHours()).slice(-2);
            const minutes = ('0' + date.getMinutes()).slice(-2);
            const seconds = ('0' + date.getSeconds()).slice(-2);   

            return `${year}_${month}_${day}_${hours}_${minutes}_${seconds}`;   
        },

        onExporting(e){
            if (e.format === 'xlsx') {
                const workbook = new Workbook();
                const worksheet = workbook.addWorksheet('Report');
                exportDataGrid({
                    component: e.component,
                    worksheet,
                    autoFilterEnabled: true,
                }).then(() => {
                    workbook.xlsx.writeBuffer().then((buffer) => {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), this.templateData.code + '_' + this.getCurrentDate() + '.xlsx');
                    });
                });
            } 
            else if (e.format === 'pdf') {
                const doc = new jsPDF();
                exportDataGridToPdf({
                    jsPDFDocument: doc,
                    component: e.component
                }).then(() => {
                    doc.save(this.templateData.code + '_' + this.getCurrentDate() + '.pdf');
                })
            }
        },
        loadReportData(){
            this.getReportColumns({report_id: this.templateData.id}).then(response => {
                this.gridColumns = [];
                this.aggregatedColumns = [];
                response.data.data.sort((a, b) => a.order - b.order).forEach(column => {
                   let colSetting = {
                       dataField: column.field_name,
                       dataType: column.data_type,
                       caption: column.title,
                       width: column.width,
                       visible: column.visible == 1,
                       format: column.format_type
                   } 
                   this.gridColumns.push(colSetting);
                   if(column.aggregate_type && column.aggregate_type != 'none'){
                       this.aggregatedColumns.push({
                           column: column.field_name,
                           summary_type: column.aggregate_type,
                           value_format: column.format_type,
                           show_aggregate_in_footer: column.show_aggregate_in_footer,
                           show_aggregate_in_group: column.show_aggregate_in_group
                       });
                   }
                });
            });
            let args = {
                date_from : undefined,
                date_to : '2025-12-31'
            };
             
            this.getReportFilters({report_id: this.templateData.id, args: args}).then(response => {
                
                this.basicInfoItems = [];
                if(response.data.data.length > 0){
                    response.data.data.sort((a, b) => Number(a.order) > Number(b.order) ? 1 : -1).forEach(filter => {  
                        let filterItem = {
                            dataField : filter.field_name,
                            label : filter.title,
                            dataType : filter.data_type
                        };
                        switch(filter.data_type){
                            case 'number':
                                filterItem.editorType = 'dxNumberBox';
                                break;
                            case 'date':
                            case 'datetime':
                                filterItem.editorType = 'dxDateBox';
                                break;
                            case 'boolean':
                                filterItem.editorType = 'dxCheckBox';
                                break;
                            default:
                                filterItem.editorType = 'dxTextBox';
                                break;
                        }
                        this.basicInfoItems.push(filterItem);
                    });
                }
                
                this.prepareFilterData();
                this.getReportsData({name: this.templateData.datasource_SP, args: this.formData}).then(response => {
                this.report_dataset =  response;
            });
            });
            
            
        },
        prepareFilterData(){
            this.basicInfoItems.forEach(item => {
                if(item.dataType == 'datetime' || item.dataType == 'date'){
                    if(this.formData[item.dataField]){
                        this.formData[item.dataField] = moment(this.formData[item.dataField]).format("YYYY-MM-DD");
                    }
                }
            });
        },
        refreshDataGrid(){
            this.loadReportData();
            this.reportGrid.refresh();
        },
        clearFilterData(){
            this.formData = {};
            this.loadReportData();
            this.reportGrid.clearFilter();
        },
        backToList(){
            this.$emit('hideReportGridView');
        }

    },
    mounted(){
        
    }
    
}

</script>