<template>
  <div class="row">
    <div class="row">
      <div class="col-lg-6">
      <Toggle :toggleText="$t('tools.configurator.import_settings.api_import_is')" :isActive="is_active" :disabled="!isActiveApiImport"
        :onChange="handleToggleApi"></Toggle>
      </div>
      <div class="col-lg-6 text-right" v-if="is_active">
        <button @click="div_is_open = !div_is_open" class="btn btn-primary">
          <i :class="div_is_open ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i>
        </button>
      </div>
    </div>
    <div v-if="is_active && div_is_open">
      <div class="row">
        <div class="form-group col-lg-4" style="margin-bottom: 0px;">
          <label class="control-label">{{ $t('tools.configurator.import_settings.credentials') }}</label>
          <b-form-select v-model="data.selected_api_credential" class="form-control"
            @change="onApiCredentialsChanged">
            <option v-for="option in credential_options" :key="option.id" :value="option.value">
              {{ option.text }}
            </option>
          </b-form-select>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3">
          <label class="control-label">{{ $t("tools.configurator.import_settings.api") }}</label>
          <b-form-select v-model="data.credential_api" class="form-control" @change="onSourceEndpointApiOptionChanged">
            <option v-for="option in api_options" :key="option.id" :value="option.value">
              {{ option.text }}
            </option>
          </b-form-select>
        </div>
        <div class="col-lg-4">
          <label class="control-label">{{ $t("email") }}</label>
          <input ref="emailInput" v-model="data.credential_user_name" class="form-control"
            :class="{ 'field-error': this.$v.data.credential_user_name.$error }" autocomplete="off" />
        </div>
        <div class="col-lg-4">
          <label class="control-label">{{ $t("password") }}</label>
          <input type="password" v-model="data.credential_password" class="form-control"
            :class="{ 'field-error': this.$v.data.credential_password.$error }" autocomplete="off" />
        </div>
      </div>
      <div class="col-lg-6" style="margin-bottom: 0px; margin-top: 10px;">
        <label class="control-label">{{ $t("tools.configurator.import_settings.credential_configuration")
          }}</label>
        <b-form-select v-model="data.selected_credential_configuration" class="form-control"
          @change="onCredentialConfigChanged">
          <option v-for="option in configuration_options" :key="option.id" :value="option.value">
            {{ option.text }}
          </option>
        </b-form-select>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <label class="control-label">{{ $t("tools.configurator.import_settings.tenant_uuid") }}</label>
          <input ref="tenantFromInput" v-model="data.configuration_tenant_uuid" class="form-control"
            :class="{ 'field-error': this.$v.data.configuration_tenant_uuid.$error }" autocomplete="off" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Toggle from "@/components/Toggle.vue";
import { required, email } from 'vuelidate/lib/validators'
import { errorMessage } from "~/helpers/common";

export default {
  components: {
    Toggle
  },
  name: 'ImportApiSettings',
  data: function () {
    return {
      is_active: this.apiConfig ? this.apiConfig.is_active === 1 : false,
      credential_options: [],
      configuration_options: [],
      api_options: [
        { id: 1, value: 'TRACKTOR', text: 'TRACKTOR' },
      ],
      data: {
        selected_api_credential: '',
        selected_credential_configuration: '',
        credential_user_name: '',
        credential_password: '',
        credential_api: '',
        configuration_tenant_uuid: '',
      },
      div_is_open: false,
    };
  },
  props: ['apiConfig', 'apiCredentials', 'isActiveApiImport'],
  methods: {
    /**
      * Updates the selected api credential and sets the corresponding username and password in the data object.
      * Then, it loads the configuration options and triggers the onCredentialConfigChanged method.
      *
      * @param {string} val - The UUID of the selected api credential.
      */
    onApiCredentialsChanged(val) {
      this.data.selected_api_credential = val;
      if (this.data.selected_api_credential) {
        let selectedCred = this.apiCredentials ? this.apiCredentials.find(credential => credential.uuid === this.data.selected_api_credential) : null;
        if (selectedCred) {
          this.data.credential_user_name = selectedCred.config.username;
          this.data.credential_password = selectedCred.config.password;
          this.data.credential_api = selectedCred.config.api;
        }
      } else {
        this.data.credential_user_name = '';
        this.data.credential_password = '';
        this.data.credential_api = this.api_options[0].value;
      }

      this.loadConfigurationOptions();
      this.onCredentialConfigChanged('');
    },
    /**
    * Updates the api configuration based on the selected credential.
    * @param {any} val - The new value of the selected api configuration.
    */
    onCredentialConfigChanged(val) {
      this.data.selected_credential_configuration = val;
      let selectedCred = this.apiCredentials ? this.apiCredentials.find(credential => credential.uuid === this.data.selected_api_credential) : null;
      if (selectedCred && this.data.selected_credential_configuration) {
        let selectedConfig = selectedCred.source_configurations.find(sourceConfig => sourceConfig.uuid === this.data.selected_credential_configuration);
        if (selectedConfig) {
          this.data.configuration_tenant_uuid = selectedConfig.config.tenant_uuid ?? '';
        }
      } else {
        this.data.configuration_tenant_uuid = '';
      }
    },
    loadConfigurationOptions() {
      if (this.data.selected_api_credential) {
        let optionId = 1;
        let selectedCred = this.apiCredentials ? this.apiCredentials.find(credential => credential.uuid === this.data.selected_api_credential) : null;
        if (selectedCred) {
          this.configuration_options = [
            { id: '', value: '', text: ' - ' + this.$t("tools.configurator.import_settings.new_configuration") + ' - ' },
            ...selectedCred.source_configurations.map(sourceConfig => {
              return {
                id: optionId++,
                value: sourceConfig.uuid,
                text: sourceConfig.config.tenant_uuid,
              };
            })
          ];
        }
      } else {
        this.configuration_options = [{ id: '', value: '', text: ' - ' + this.$t("tools.configurator.import_settings.new_configuration") + ' - ' }];
      }
    },
    handleToggleApi(is_checked) {
      this.is_active = is_checked;
      if (is_checked) {
        this.div_is_open = is_checked;
      }
      this.apiConfig.is_active = is_checked ? 1 : 0;
    },
    apiConfigurationChange() {
      this.is_active = this.apiConfig ? this.apiConfig.is_active === 1 : false;
      this.data.selected_api_credential = '';
      this.data.selected_credential_configuration = '';
      let temp_api_configuration = '';

      if (this.is_active) {
        if (this.apiConfig.source_config_uuid && this.apiConfig.source_config_uuid !== '') {
          let credential = this.apiCredentials ? this.apiCredentials.find(credential => credential.source_configurations.find(sourceConfig => sourceConfig.uuid === this.apiConfig.source_config_uuid)) : null;
          if (credential) {
            this.data.selected_api_credential = credential.uuid;
            this.data.selected_credential_configuration = this.apiConfig.source_config_uuid;
            temp_api_configuration = this.apiConfig.source_config_uuid;
          }
        }
      }

      this.onApiCredentialsChanged(this.data.selected_api_credential);
      this.onCredentialConfigChanged(temp_api_configuration);
    },
    validate() {
      /**
       * Validates the form fields for importing api settings.
       * If the 'is_active' flag is true, the following fields are required:
       * - data.credential_user_name
       * - data.credential_password
       * - data.credential_api
       * - data.configuration_tenant_uuid
       * 
       * If the 'is_active' flag is false, the function returns true.
       * 
       * @param {boolean} is_active - Flag indicating whether the api settings are active or not.
       * @returns {object|boolean} - An object containing validation rules for the required fields if 'is_active' is true, otherwise returns true.
       */

      let isValid = true;

      if (this.is_active) {
        this.$v.data.$touch();
        if (this.$v.data.$invalid) {
          errorMessage(this, this.$t('fill_out_error'));
          isValid = false;
        }
      }

      return isValid;
    },
    onSourceEndpointApiOptionChanged(val) {
    },
  },
  watch: {
    apiConfig: {
      handler: function (newVal) {
        this.apiConfigurationChange();
      },
      deep: true,
    },
    apiCredentials: {
      handler: function (newVal) {
        this.apiCredentials = newVal;
        let optionId = 1;
        this.credential_options = [
          { id: '', value: '', text: ' - ' + this.$t("tools.configurator.import_settings.new_credential") + ' - ' },
          ...this.apiCredentials.map(credential => {
            return {
              id: optionId++,
              value: credential.uuid,
              text: credential.config.username,
            };
          })
        ];
      },
      deep: true,
    },
    data: {
      handler: function (newVal) {
        this.$emit('onApiDataChanged', newVal);
      },
      deep: true,
    },
  },
  validations() {
    return {
      data: {
        credential_user_name: {
          required, email
        },
        credential_password: {
          required,
        },
        credential_api: {
          required,
        },
        configuration_tenant_uuid: {
          required
        },
      },
    }
  },
}
</script>
<style>
@import '@fortawesome/fontawesome-free/css/all.css';
</style>