<template>
  <div>
    <card title="Work list"></card>
    <div class="content-body pt-4">
      <div class="row">
        <div id="toast-msg"></div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <DxDataGrid
              :ref="workGridRefKey"
              :data-source="workDatasource"
              :show-borders="false"
              :remote-operations="gridRemoteOperations"
              :element-attr="gridAttributes"
              :allow-column-resizing="true"
              column-resizing-mode="widget"
              :column-auto-width="true"
              :on-row-prepared="rowMarker"
              :on-editor-preparing="onEditorPreparing"
              :on-editing-start="handleRowEdit"
          >
            <DxColumn
                data-field="work_started_at"
                data-type="date"
                caption="Data"
                format="yyyy-MM-dd"
                width="100px"
            >
            </DxColumn>
            <DxColumn
                data-field="work.uuid"
                data-type="string"
                caption="Lucrare"
                :min-width=120
                width="auto"
                :editor-options="columnEditorOptions"
            >
              <DxLookup :data-source="workTypes" value-expr="uuid" display-expr="name">
              </DxLookup>
            </DxColumn>
            <DxColumn
                data-field="work_machine.uuid"
                data-type="string"
                caption="Tractor"
                :min-width=120
                width="auto"
                :editor-options="columnEditorOptions"
            >
              <DxLookup :data-source="workMachineList" value-expr="uuid" display-expr="name">
              </DxLookup>
            </DxColumn>
            <DxColumn
                data-field="equipment.uuid"
                data-type="string"
                caption="Utilaj"
                :min-width=120
                width="auto"
                :editor-options="columnEditorOptions"
            >
              <DxLookup :data-source="equipmentList" value-expr="uuid" display-expr="name">
              </DxLookup>
            </DxColumn>
            <DxColumn
                data-field="season.uuid"
                data-type="string"
                caption="Season"
                :min-width=150
                width="auto"
                :editor-options="columnEditorOptions"
                :set-cell-value="setParcelValue"
            >
              <DxLookup :data-source="seasonsList" value-expr="uuid" display-expr="name">
              </DxLookup>
            </DxColumn>
            <template #dropDownBoxEditor="{ data: cellInfo }">
              <ParcelComboBoxComponent
                :value="cellInfo.value"
                :on-value-changed="cellInfo.setValue"
              />
            </template>
            <DxColumn
                data-field="parcel.uuid"
                data-type="string"
                caption="Parcelă"
                :min-width=120
                width="auto"
                :editor-options="columnEditorOptions"
            >
              <DxLookup :data-source="getParcelList" value-expr="parcel_uuid" display-expr="parcel_name">
              </DxLookup>
            </DxColumn>
            <DxColumn
                data-field="parcel.surface"
                data-type="number"
                caption="Suprafață"
                width="auto"
                :editor-options="columnEditorOptions"
                format="#,##0.## ha"
                :allow-editing="false"
            >
            </DxColumn>
            <DxColumn
                data-field="net_surface_worked"
                data-type="number"
                caption="Suprafață prelucrată"
                width="auto"
                :editor-options="columnEditorOptions"
                format="#,##0.## ha"
            >
            </DxColumn>
            <DxColumn
                data-field="notes"
                data-type="string"
                caption="Comentarii"
                width="auto"
                :editor-options="columnEditorOptions"
            >
            </DxColumn>
            <DxPaging :total="works.lenght" :page-size="15"/>
            <DxPager
                :visible="true"
                :allowed-page-sizes="pageSizes"
                display-mode="compact"
                :show-page-size-selector="true"
                :show-info="true"
                :show-navigation-buttons="true"
            />
            <DxEditing
                :allow-adding="false"
                :allow-updating="true"
                :allow-deleting="false"
                :use-icons="true"
                mode="popup"
            >
              <DxTexts
                  confirm-delete-message="Sigur vreți să ștergeți lucrarea?",
                  confirm-delete-title="Confirmare ștergere"
              />
              <DxPopup :wrapper-attr="popupAttributes"
              :show-title="true"
              :width="840"
              :height="425"
              title="Work details"
              ></DxPopup>
            </DxEditing>
            <DxHeaderFilter :visible="true" />
            <DxFilterRow :visible="true" apply-filter="auto"/>
            <DxSelection mode="single" />
            <DxScrolling column-rendering-mode="virtual"/>
          </DxDataGrid>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import * as endpoints from '../../../store/api/endpoints'
import {
  DxColumn,
  DxDataGrid,
  DxEditing,
  DxFilterRow,
  DxHeaderFilter,
  DxLookup,
  DxPager,
  DxPaging,
  DxRequiredRule,
  DxSelection,
  DxStringLengthRule,
  DxTexts,
  DxScrolling,
  DxPopup
} from 'devextreme-vue/data-grid';
import {canEdit} from "@/helpers/Permissions";
import Forbidden from "~/pages/errors/Forbidden.vue";
import CustomStore from 'devextreme/data/custom_store';
import * as api from '../../../store/api/index'
import {
  getFilterDataByName,
  getSortByProperty,
  getSortDirection,
  ROW_BACKGROUND_COLOR
} from "@/helpers/dxGridHelper";
import ParcelComboBoxComponent from './ParcelComboBoxComponent.vue';
import notify from "devextreme/ui/notify";

export default {
  name: "WorkListDesktop",
  middleware: ['auth', 'check-company-admin'],
  data(){
    return {
      workTypes: [],
      workMachineList: [],
      equipmentList: [],
      seasonsList: [],
      parcelList: [],
      gridAttributes:{
        class: 'admin-grid'
      },
      popupAttributes: {
          class: 'work-input-edit-popup'
      },
      workGridRefKey: 'work-grid',
      gridRemoteOperations: {
        paging: true,
        filtering : true,
        sorting: true
      },
      pageSizes: [15, 25, 100],
      columnEditorOptions: { showClearButton: true },
      works: [],
      parcels: [],
      workDatasource: new CustomStore({
        key: 'uuid',
        load: this.load,
        insert: this.insert,
        update: this.update,
        remove: this.deleteProduct
      })
    };
  },
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxPager,
    DxEditing,
    DxTexts,
    DxLookup,
    DxRequiredRule,
    DxStringLengthRule,
    DxHeaderFilter,
    DxFilterRow,
    DxSelection,
    Forbidden,
    DxScrolling,
    DxPopup,
    ParcelComboBoxComponent
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      users: 'user/users',
    }),
    canEdit() {
      return canEdit(this);
    },
  },
  methods: {
    ...mapActions({
      getWorks: 'seasonOnParcelHasCulture/getWorkListForDesktop',
      getParcelsAndSeasons: 'seasonOnParcelHasCulture/getParcelsAndSeasons',
      changeWork: 'seasonOnParcelHasCulture/updateWork',
      getWorkTypes: "works-manager/getWorks",
      getWorkMachineList: 'works-work-machines/getWorkMachines',
      getWorkMachineEquipmentsList: 'works-work-machines/getWorkMachineEquipments',
      getSeasons: 'seasons/getSeasons'
    }),
    handleRowEdit(e) {
      // Check if the current row has batch_item
      if (e.data.batch_item) {
        notify(
            {
              message: "This row cannot be edited as it has batch items.",
              width: "auto",
              position: {
                at: "center",
                my: "start",
                of: "#toast-msg"
              }
            },
            'error',
            3000
        );
        e.cancel = true;
      } else {
        e.cancel = false;
      }
    },
    getParcelList(options) {
      if(options?.data?.season?.uuid === undefined){
        return this.parcels;
      }
      else{
        return this.parcels.filter(parcel => parcel.season_uuid === options?.data?.season?.uuid);
      }
    },
    setParcelValue(newData, value, currentRowData) {
      newData.parcel = {
        uuid: null,
      };
      newData.season = {
        uuid: value,
      };
    },
    rowMarker(e) {
      if (e.rowType === "data" && e.data.batch_item) {
        e.rowElement.style.backgroundColor = ROW_BACKGROUND_COLOR;
      }
    },
    onEditorPreparing(e){
      if(e.parentType === 'dataRow' && e.dataField === 'parcel.uuid'){
        const isSeasonNotSet = e.row.data.season?.uuid === undefined || e.row.data.season?.uuid === null;
        e.editorOptions.disabled = isSeasonNotSet;
      }
    },
    async load(loadOptions){
      let pageLimit = loadOptions.take;
      let pageNumber = (loadOptions.skip + loadOptions.take)/pageLimit;
      let dataSource =  {
        data:[],
        totalCount:0
      };
      let payload = {
        page: pageNumber,
        limit: pageLimit,
        sortBy: getSortByProperty(loadOptions) ?? 'uuid',
        sortDirection: getSortDirection(loadOptions) ?? 'ASC',
        work_started_at: getFilterDataByName(loadOptions, "work_started_at", true),
        notes: getFilterDataByName(loadOptions, "notes"),
        work_name: getFilterDataByName(loadOptions, "work_name"),
        machine_name: getFilterDataByName(loadOptions, "machine_name"),
        equipment_name: getFilterDataByName(loadOptions, "equipment"),
        season_name: getFilterDataByName(loadOptions, "season.name"),
        season_uuid: getFilterDataByName(loadOptions, "season.uuid"),
        parcel_name: getFilterDataByName(loadOptions, "parcel.name"),
        parcel_uuid: getFilterDataByName(loadOptions, "parcel.uuid"),
        parcel_surface: getFilterDataByName(loadOptions, "parcel.surface"),
        net_surface_worked: getFilterDataByName(loadOptions, "net_surface_worked")
      }
      await api.fetchFull(endpoints.WORK_LIST_DESKTOP, payload, null,(response) => {
        dataSource.data = response.data.data;
        dataSource.totalCount = response.data.pagination.total
      });

      return dataSource;
    },
    async update(key, obj){
      const updatable = Object.assign(
          {},
          this.works.find(item => item.uuid === key),
          obj
      );

      updatable.work_uuid = updatable.work.uuid;
      updatable.machine_uuid = updatable.work_machine.uuid;
      updatable.equipment_uuid = updatable.equipment.uuid;
      updatable.season_uuid = updatable.season.uuid;
      updatable.parcel_uuid = updatable.parcel.uuid;
      delete updatable.equipment;
      delete updatable.equipments;
      delete updatable.work;
      delete updatable.parcel;
      delete updatable.season;
      delete updatable.work_machine;

      // Call the update API
      await this.changeWork(updatable);

      // Reload the works after successful update
      try {
        const response = await this.getWorks({ limit: 1000 });
        this.works = response.data;
        notify(
            {
              message: "Work has been updated.",
              width: "auto",
              position: {
                at: "center",
                my: "start",
                of: "#toast-msg"
              }
            },
            'success',
            3000
        );
      } catch (error) {
        console.error("Error reloading works: ", error);
      }
    },
  },
  mounted(){
    this.getWorks({limit: 1000}).then(response => {
      this.works = response.data;
    });
    this.getParcelsAndSeasons({}).then(response => {
      this.parcels = response.data;
    });
    this.getWorkTypes().then(response => {
      this.workTypes = response.data;
    });
    this.getWorkMachineList().then(response => {
      this.workMachineList = response.data;
    });
    this.getWorkMachineEquipmentsList().then(response => {
      this.equipmentList = response;
    });
    this.getSeasons().then(response => {
      this.seasonsList = response.data;
    });
  }
}

</script>

<style lang="scss">
.work-input-edit-popup{
  .dx-popup-normal{
    border-radius: 0px;
  }
}
</style>
