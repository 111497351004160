<template>
  <div class="history-holder" v-if="canEdit">
    <card :title="$t('import_history') +
      ' (' +
      $t('tools.configurator.' + lineToUnderscore(import_ref)) +
      ')'
      " id="import-history" titleBottomPadding="0">
      <div class="row d-flex justify-content-end" style="width: 100%; padding-bottom: 8px;">
        <button class="btn btn-md btn-secondary" @click="goBackToTypeList" style="width: 250px;">
          {{ $t("back_to_list") }}
        </button>
      </div>
      <div v-if="(activeApiImportConfig ?? false)">
        <div class="api-import-box">
          <div style="width: 200px;">
            <label class="api-import-label">{{ $t('tools.import.tracktor_api_import') }}</label>
          </div>
          <div style="width: 200px;">
            <date-picker2
              v-model="selectedApiDate"
              :value-type="selectedApiDateInput"
              format="YYYY-MM-DD"
              type="date"
              :input-class="['date-picker-style']"
              @change="onApiDateChanged"
              :disabled-date="(date) => date > new Date()"
            ></date-picker2>
          </div>
          <div style="width: 250px;">
            <button class="btn btn-primary api-import-send-btn" @click="sendApiImportRequest" :disabled="sendApiDisabled">
              {{ $t('tools.import.get_data') }}
            </button>
          </div>
        </div>
      </div>
      <file-list ref="importFileList" :data="uploaded_files_history" @importValidatedDataEvent="importValidatedData"></file-list>
    </card>
    <ConfirmationDialog
      :show="showConfirmationDialog"
      @close="cancelValidatedDataImport"
      @confirm="startValidatedDataImport"
      :title="$t('tools.import.transfer_to_live_data')"
      :message="$t('tools.import_validator.transfer_to_live_data_question')"
    ></ConfirmationDialog>
  </div>
  <div v-else>
    <forbidden></forbidden>
  </div>
</template>

<script>
import FileList from "../import/partials/FileList";
import ConfirmationDialog from "@/components/Modals/ConfirmationDialog";
import { mapActions, mapGetters } from "vuex";
import {
  canDelete,
  canEdit,
  permissions,
  permissionTypes,
} from "@/helpers/Permissions";
import Forbidden from "@/pages/errors/Forbidden";
import { getCalculateDateDaysAgo } from "~/helpers/dateHelper";
import {
  lineToUnderscore,
  successMessage,
  errorMessageList,
  errorMessage,
} from "~/helpers/common";
import Cookies from "js-cookie";
import DatePicker2 from "vue2-datepicker";
import moment from 'moment'

export default {
  name: "ImportHistory",
  data() {
    return {
      uploaded_files_history: this.recentlyUploadedFiles
        ? this.recentlyUploadedFiles
        : [],
      intervalId: null, // Store the interval ID here
      import_type: "",
      import_ref: "data-import",
      showConfirmationDialog: false,
      transferData: null,
      activeApiImportConfig: null,
      selectedApiDate: null,
      selectedApiDateInput: null,
      sendApiDisabled: true,
    };
  },
  components: {
    FileList,
    Forbidden,
    ConfirmationDialog,
    DatePicker2
  },
  computed: {
    ...mapGetters({
      recentlyUploadedFiles: "company/recentlyUploadedFiles",
      importConfigurations: 'importConfig/importConfigurations',
      importSourceCredentials: 'importConfig/importSourceCredentials',
    }),
    config() {
      return {
        permission: permissions.CONFIGURATOR,
        permission_type: permissionTypes.module,
      };
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this);
    },
  },
  methods: {
    ...mapActions({
      getRecentlyUploadedFiles: "company/getRecentlyUploadedFiles",
      transferValidatedDataToLiveData: "importConfig/transferValidatedDataToLiveData",
      importApiCall: "importConfig/importApiCall",
    }),
    getUploadedFiles(company) {
      if (company && company.uuid) {
        this.getRecentlyUploadedFiles({
          company_uuid: company.uuid,
          import_type: this.import_type,
          limit: 200,
          date_from: getCalculateDateDaysAgo(60),
        });
      }
    },
    cancelValidatedDataImport() {
      this.showConfirmationDialog = false;
      this.transferData = null;
      this.$refs.importFileList.setItemToLiveData(null);
    },
    startValidatedDataImport() {
      this.showConfirmationDialog = false;
      if (!this.transferData) {
        return;
      }
      const transfer_settings = {
        import_id: this.transferData.import_id,
        import_type: this.transferData.import_type,
        department_uuid: this.transferData.department_uuid,
        warehouse_uuid: this.transferData.warehouse_uuid,
        season_uuid: this.transferData.season_uuid,
        source_data_type: this.transferData.source_data_type,
        uploaded_file_id: this.transferData.uploaded_file_id,
        session_id: this.transferData.session_id,
        filename: this.transferData.filename,
      };

      let vm = this;
      this.transferValidatedDataToLiveData(transfer_settings)
        .then((response) => {
          successMessage(vm, response.message);
          this.$refs.importFileList.updateProcessedAfterValidation(transfer_settings.import_id, 1);
          this.$refs.importFileList.setItemToLiveData(null);
        })
        .catch((e) => {
          errorMessageList(vm, e.errors);
          this.$refs.importFileList.setItemToLiveData(null);
        });
    },
    importValidatedData(data) {
      this.showConfirmationDialog = true;
      this.transferData = data;
    },
    lineToUnderscore(value) {
      return lineToUnderscore(value);
    },
    goBackToTypeList() {
      this.$router.back();
    },
    sendApiImportRequest() {
      if(!this.selectedApiDate || !this.activeApiImportConfig || !this.activeApiImportConfig.source_config_uuid){
        errorMessage(this, this.$t('tools.import.api_import_required_error'));
        return;
      }

      const payload = {
        date: this.selectedApiDate,
        import_configuration_uuid: this.activeApiImportConfig.uuid,
      };

      this.sendApiDisabled = true;

      let vm = this;
      this.importApiCall(payload)
        .then((response) => {
          successMessage(vm, response.message);
          vm.getUploadedFiles(vm.company);
        })
        .catch((e) => {
          errorMessageList(vm, e.errors);
        });
    },
    onApiDateChanged() {
      this.sendApiDisabled = false;
    },
  },
  async mounted() {
    if (this.$route.query.import_type_id) {
      this.import_type = this.$route.query.import_type_id;
      this.import_ref = this.$route.query.import_typ_ref;
    }

    let vm = this;
    const dom = await this.$store.dispatch("auth/checkDomain", {
      domain: Cookies.get("domain"),
    });
    this.company = dom;
    vm.getUploadedFiles(dom);
    this.intervalId = setInterval(function () {
      vm.getUploadedFiles(vm.company);
    }, 10000);

    if(this.importConfigurations){
      this.activeApiImportConfig = this.importConfigurations.find(
        (config) =>
          config.import_type === this.import_type &&
          config.is_active === 1 &&
          config.source_type === "API_IMPORT" &&
          config.source_config_uuid
      );
      /*if(this.activeApiImportConfig){
        this.selectedApiDate = moment().format('YYYY-MM-DD');
      }*/
    }
  },
  beforeDestroy() {
    // Use beforeUnmount() for Vue 3
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  watch: {
    recentlyUploadedFiles: {
      handler: function (newVal) {
        this.uploaded_files_history = newVal;
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.api-import-box {
  border: 1px solid lightgray;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
  width: 750px;
}

.api-import-label {
  font-weight: bold;
  margin-right: 10px;
  margin-top: 2px;
}

.api-import-send-btn {
  margin-left: 30px;
  margin-top: 5px;
}
</style>