<template>
  <DxDataGrid
      ref="rcgrid"
      :data-source="dataSource"
      :show-borders="true"
      :remote-operations="gridRemoteOperations"
      @init-new-row="initNewColumn($event)"
  >
    <DxColumn
        dataField="field_name"
        :caption="$t('reporting.tabs.columns.field_name')"
        :width="'20%'"
        :filter-operations="['contains']"
    >
      <DxRequiredRule :message="$t('reporting.validation.field_name_is_required')"/>
    </DxColumn>
    <DxColumn
        dataField="title"
        :caption="$t('reporting.tabs.columns.column_title')"
        :width="'20%'"
        :filter-operations="['contains']"
    >
      <DxRequiredRule :message="$t('reporting.validation.title_is_required')"/>
    </DxColumn>
    <DxColumn
        dataField="width"
        alignment="center"
        :caption="$t('reporting.tabs.columns.width')"
        :width="'10%'"
        :allowFiltering="false"
        :allowSorting="false"
    >
      <DxRequiredRule :message="$t('reporting.validation.width_is_required')"/>
    </DxColumn>
    <!-- lookup -->
    <DxColumn
        dataField="visible"
        dataType="number"
        alignment="center"
        :caption="$t('reporting.tabs.columns.visible')"
        :width="'20%'"
        :filter-operations="['=']"
    >
      <DxLookup
          :data-source="visibleList"
          value-expr="value"
          display-expr="text"
      />
    </DxColumn>
    <DxColumn
        dataField="order"
        dataType="number"
        alignment="center"
        :caption="$t('reporting.tabs.columns.order')"
        :width="'20%'"
        :filter-operations="['=']"
    >
    </DxColumn>
    <DxColumn
        dataField="data_type"
        alignment="center"
        :caption="$t('reporting.tabs.columns.data_type')"
        :width="'20%'"
        :filter-operations="['=']"
    > 
    <DxLookup
          :data-source="dataTypeList"
          value-expr="value"
          display-expr="text"
      />
    </DxColumn>
    <DxColumn
        dataField="format_type"
        alignment="center"
        :caption="$t('reporting.tabs.columns.format_type')"
        :width="'20%'"
        :filter-operations="['=']"
    >
    </DxColumn>
    <DxColumn
        dataField="aggregate_type"
        alignment="center"
        :caption="$t('reporting.tabs.columns.aggregate_type')"
        :width="'20%'"
        :filter-operations="['=']"
    >
    <DxLookup
          :data-source="aggregateList"
          value-expr="value"
          display-expr="text"
      />
    </DxColumn>
    <DxColumn
        dataType="boolean"
        dataField="show_aggregate_in_footer"
        alignment="center"
        :caption="$t('reporting.tabs.columns.show_in_footer')"
        :width="'20%'"
        :filter-operations="['=']"
    >
    </DxColumn>
    <DxColumn
        dataType="boolean"
        dataField="show_aggregate_in_group"
        alignment="center"
        :caption="$t('reporting.tabs.columns.show_in_group')"
        :width="'20%'"
        :filter-operations="['=']"
    >
    </DxColumn>
    
    <DxFilterRow :visible="true"/>
    <DxEditing
        :allow-adding="allowEditing()"
        :allow-updating="allowEditing()"
        :allow-deleting="allowDeleting"
        mode="form"
        :use-icons="true"
    >
      <DxTexts
          :confirm-delete-message="$t('reporting.validation.confirm_delete')"
          :confirm-delete-title="$t('confirm_delete_title')"
      />
    </DxEditing>
  </DxDataGrid>
</template>

<script>
import DxDataGrid, {
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxLookup,
  DxRequiredRule,
  DxTexts
} from 'devextreme-vue/data-grid';
import {mapActions} from "vuex";
import CustomStore from "devextreme/data/custom_store";
import DataSource from 'devextreme/data/data_source';
import {canDelete, canEdit} from "@/helpers/Permissions";

export default {
  props: {
    data: Object,
  },
  components: {
    DxLookup,
    DxFilterRow,
    DxRequiredRule,
    DxTexts,
    DxEditing,
    DxColumn,
    DxDataGrid,
  },
  data() {
    return {
      gridRemoteOperations: {
        filtering : true,
        sorting: true
      },
    }
  },
  computed: {
    dataSource() {
      return new DataSource({
        store: new CustomStore({
          key: 'id',
          load: this.load,
          insert: this.insert,
          update: this.update,
          remove: this.remove
        })
      });
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this);
    },
    visibleList() {
      return [
        {value: 1, text: this.$t('common.yes')},
        {value: 0, text: this.$t('common.no')}
      ];
    },
    dataTypeList(){
      return [
        {value:'string', text: 'String'},
        {value: 'number', text: 'Number'},
        {value: 'date', text: 'Date'},
        {value: 'boolean', text: 'Boolean'},
        {value: 'datetime', text: 'Datetime'},
      ];
    },
    aggregateList() {
      return [
        {value: 'none', text: 'None'},
        {value: 'sum', text: 'SUM'},
        {value: 'avg', text: 'AVG'},
        {value: 'min', text: 'MIN'},
        {value: 'max', text: 'MAX'},
        {value: 'count', text: 'COUNT'},
      ];
    },
  },
  methods: {
    ...mapActions({
      get: 'reporting/getReportColumns',
      create: 'reporting/createReportColumn',
      change: 'reporting/updateReportColumn',
      delete: 'reporting/deleteReportColumn',
    }),
    async load(data) {
      let dataSource =  {
        data:[],
        totalCount:0
      };

      await this.get({
        report_id: this.data.id,
        filter: data.filter,
        sort: data.sort,
      }).then((resp) => {
        dataSource.data = resp.data.data;
        dataSource.totalCount = resp.data.pagination.total;
      });

      return dataSource;
    },
    async insert(data) {
      await this.create(
          Object.assign({}, data, {
            report_id: this.data.id,
          })
      )
    },
    async update(key, obj) {
      const updatable = Object.assign(
          {},
          this.dataSource.items().find(item => item.id === key),
          obj
      );

      await this.change(
          Object.assign({}, updatable, {
            id: key,
            report_id: this.data.id,
          })
      )
    },
    remove(id) {
      this.delete({
        id: id,
      });
    },
    allowEditing(e) {
      return this.canEdit;
    },
    allowDeleting(e) {
      //return this.canDelete && e.row.data.is_deletable === 1;
      return true;
    },
    initNewColumn(e){
      e.data.width = 'auto';
      e.data.visible = 1;
      e.data.data_type = 'string';
      e.data.aggregate_type = 'none';
      e.data.format_type = '#,##0.##';
      e.data.report_id = this.data.id;
      
    let maxOrder = 0;
      this.dataSource.items().forEach(item => {
        if(item.order > maxOrder){
          maxOrder = item.order;
        }
      });
      e.data.order = maxOrder + 1;

    }
  },
}
</script>
