import * as api from '../../api/index'
import * as endpoints from '../../api/endpoints'
import * as types from "../../mutation-types";
import {GET_PARCELS_FOR_SEASON} from "../../mutation-types";

const actions = {
    getParcels({commit}, payload) {
        return api.fetch(endpoints.PARCELS, payload, commit,(response) => {
          commit(types.SET_PARCELS, response.data.data);
          commit("common/"+types.SET_PAGINATION, response.data.pagination, { root: true });
        });
    },
    getParcelsBySeasonUuid({commit}, payload) {
        return api.fetch(endpoints.PARCELS_FOR_SEASON+'/'+payload.uuid, payload, commit,(response) => {
            commit(types.GET_PARCELS_FOR_SEASON, response.data);
        });
    },
    getParcel({commit}, payload) {
      return api.fetch(endpoints.PARCELS+'/'+payload.uuid, payload, commit,(response) => {
        commit(types.SET_PARCEL, response.data);
      });
    },
    getParcelGroups({commit}, payload) {
      payload.is_group = 1;
      payload.venablePagination = 0;
      payload.limit = 9999;
      return api.fetch(endpoints.PARCELS, payload, commit,(response) => {
        commit(types.SET_PARCEL_GROUPS, response.data.data);
      });
    },
    createParcel({commit}, payload) {
      return api.post(endpoints.PARCELS, payload,  commit,(response) => {
        commit(types.SET_PARCEL, response.data);
      });
    },
    updateParcel({commit}, payload) {
      return api.put(endpoints.PARCELS+'/'+payload.uuid, payload, commit, (response) => {
        commit(types.SET_PARCEL, response.data);
      })
    },
    deleteParcel({commit}, payload) {
      return api.remove(endpoints.PARCELS+'/'+payload.uuid, payload,  commit,(response) => {
        commit(types.SET_PARCEL, response.data);
      })
    },
}

export default actions;
