<template>
    <DxScrollView id="stockScrollView" ref="scrollView" :elementAttr="scrollListAttributes" :use-native="true" @reach-bottom="reachBottom" @scroll="scrollTest"  @pull-down="pullDown">
            <div class="stock_list_holder">
                <div v-for="(stock_data, r_idx) in stock_list_data" :key="r_idx">
                    <div class="stock_item">
                        <div class="stock_main_icon">
                            <div class="stock_main_icon_file">
                                <img src="@/assets/images/icons/samanta.svg"/>
                            </div>
                        </div>
                        <div class="stock_main_info" @click="productClickOnSelectionMode(stock_data, r_idx)">
                            <div class="product_name">{{stock_data.code ? `${stock_data.name} (${stock_data.code})` : stock_data.name}}</div>
                            <div class="product_type">{{stock_data.product_type ? stock_data.product_type.name : 'Altele'}}</div>
                        </div>
                        <div class="stock_quantity">
                            {{stock_data.stock_list?.length > 0 ? stock_data.stock_list.map(s => s.quantity).reduce((x, y) => { return (parseFloat(x) + parseFloat(y)).toFixed(2);} ) : 0 }} {{stock_data?.measure_unit?.symbol}}
                        </div>
                        <div class="expand_icon_holder">
                            <div :class="stock_data.expanded ? 'expand_icon_expanded' : 'expand_icon'" @click="expandStoc(stock_data, r_idx)">
                                <img v-show="stock_data.expanded" src="@/assets/images/icons/arrow_up_green.svg"/>
                                <img v-show="!stock_data.expanded" src="@/assets/images/icons/arrow_down.svg"/>
                            </div>
                        </div>
                    </div>
                    <stock-locations :stockData="stock_data" v-show="stock_data.expanded" :selectionMode="selectionMode"
                        @warehouseClickOnSelectionMode="warehouseClickOnSelectionMode" @openHistoryDialog="showProductHistory"></stock-locations>
                </div>
                <div style="height: 40px"></div>
                <toast-message ref="toastMessage"></toast-message>
                <product-history :showPopup="productHistoryDialogVisibility" @hidden="hideProductHistory" :templateData="historyTemplateData"></product-history>
            </div>
        </DxScrollView>
</template>

<script>

import { mapActions } from 'vuex'
import Vue from "vue";
import infiniteScroll from "vue-infinite-scroll";
Vue.use(infiniteScroll);
import StockLocations from './stockLocations.vue';
import { DxScrollView } from "devextreme-vue/scroll-view";
import ToastMessage from "@/components/Mobile/toastMessage.vue"
import ProductHistory from "../dialogs/productHistory.vue";

export default {
    name:"StockList",
    props: {
         fillAtMount: {
            type: Boolean,
            default: true,
        },
        filterData: {
            type: Object,
            default: null
        },
        selectionMode: {
            type: Boolean,
            default: false,
        },
        hideZeroStock: {
            type: Boolean,
            default: false,
        },
    },
    data(){
        return{
            noStockData: false,
             scrollListAttributes: {
                class: 'stock_list_scroller'
            },
            executeFillStock: true,
            pagination: {},
            scrollOffset_top: 0,
            stock_list_data: [],
            productHistoryDialogVisibility: false,
            historyTemplateData:{
                product: {},
                warehouse: {}
            },
        };
    },
    watch: {
        scrollOffset_top:{
            handler: function(newVal, oldVal){
                if(newVal < oldVal){
                    this.$emit('scrollDown');
                }
                if(newVal > oldVal){
                    this.$emit('scrollUp');
                }
            }
        },
        filterData: {
             handler: function (newVal, oldVal) {
                if(newVal != null){
                    this.fillStock(1, newVal, this.fillCallback);
                }
            },
            deep: true,
        },
    },
    components: {
        StockLocations,
        DxScrollView,
        ToastMessage,
        ProductHistory
    },
    methods: {
         ...mapActions({
            getSmProductsFull: 'smProducts/getSmProductsFull',
        }),
        onScroll(){},
        expandStoc(stock_data, idx){
            stock_data.expanded = !stock_data.expanded;
            this.$set(this.stock_list_data, idx, stock_data);
        },
        reachBottom(){
            if(this.pagination.current_page < this.pagination.last_page){
                this.fillStock(this.pagination.current_page + 1, this.filterData, this.fillCallback);
            }
        },
        scroll(event){
            this.scrollOffset_top = event.scrollOffset.top;
        },
        pullDown(event){
             event.component.release();
        },
        scrollTest(){},
        fillStock(page_number, filter = null, callback = null){
            let limit = (this.$refs['scrollView'].instance._$element[0].clientHeight/75 + 1).toFixed(0);

            if(this.executeFillStock){
                filter = filter ?? {};
                filter.page = page_number;
                filter.limit = limit;
                if(this.hideZeroStock){
                    filter.has_stock= 1;
                }

                if(page_number == 1){
                    this.$refs['scrollView'].instance.release(false);
                }

                this.getSmProductsFull(filter).then((response) => {
                    if(page_number == 1){
                        this.stock_list_data = [];
                        this.$set(this.stock_list_data, [] );
                    }
                    response.data.data.forEach((stock) => {
                        stock.selected = false;
                        stock.expanded = false;
                        if(this.stock_list_data.find(obj => obj.uuid == stock.uuid) === undefined){
                            this.stock_list_data.push(stock);
                            this.$set(this.stock_list_data, this.stock_list_data.length - 1, stock );
                        }
                    });
                    
                    this.pagination = response.data.pagination;
                    this.noStockData = this.stock_list_data.length == 0;
                    if(callback != null){
                        callback();
                    }
                });
            }
            this.$set(this, 'executeFillStock', true);
        },
        fillCallback(){
           if (this.pagination) {
             if(this.pagination.current_page == this.pagination.last_page || this.stock_list_data.length == 0){
               this.$refs['scrollView'].instance.release(true);
             }
             else{
               this.$refs['scrollView'].instance.release(false);
             }
             this.$refs['scrollView'].instance.update();
             if(this.pagination.current_page == 1){
               this.$refs['scrollView'].instance.scrollTo(0)
             }
           }
        },
        reloadList(){
            this.fillStock(1, this.filterData, this.fillCallback);
        },
        productClickOnSelectionMode(stock_data, idx){
            if(this.selectionMode){
                if(stock_data.stock_list && stock_data.stock_list.length == 0){
                    this.$refs.toastMessage.showInfo('Produsul nu există in stoc!');
                }
                if(stock_data.stock_list && stock_data.stock_list.length == 1){
                    this.$emit('productSelected', stock_data, stock_data.stock_list[0]);
                }
                if(stock_data.stock_list && stock_data.stock_list.length > 1){
                    stock_data.expanded = true;
                    this.$set(this.stock_list_data, idx, stock_data);
                }
            }
        },
        warehouseClickOnSelectionMode(stock_data, warehouse){
            if(warehouse.quantity > 0){
                this.$emit('productSelected', stock_data, warehouse);
            }
            else{
                this.$refs.toastMessage.showInfo('Produsul nu există in stoc!');
            }
        },
        showProductHistory(product, warehouse){
            this.historyTemplateData.product = product;
            this.historyTemplateData.warehouse = warehouse;
            this.productHistoryDialogVisibility = true;

        },
        hideProductHistory(){
            this.productHistoryDialogVisibility = false;
        },
    },
    mounted(){
        if(this.fillAtMount){
            this.fillStock(1, this.filterData, this.fillCallback);
        }
    }
}
</script>
