<template>
  <div class="row">
    <div class="col-lg-6" style="margin-bottom: 0px; margin-top: 10px">
      <label class="control-label">{{
        $t("tools.configurator.import_templates")
      }}</label>
      <b-form-select v-model="selected_template" class="form-control" @change="onSelectedTemplateChange">
        <option v-for="option in validatorSettings" :key="option?.template_uuid" :value="option">
          {{ option?.template_name }}
        </option>
      </b-form-select>
    </div>
    <div class="row validator_settings" v-if="selected_template ?? false">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" id="parcel_full_identification"
          v-model="parcel_full_identification" @change="
            handleToggleSettings(
              parcel_full_identification,
              'parcel_full_identification'
            )
            " />
        <label class="form-check-label" for="parcel_full_identification">
          {{
            $t("tools.configurator.import_settings.parcel_full_identification")
          }}
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="checkbox" id="check_rows_before_transfer_to_live_data"
          v-model="check_rows_before_transfer_to_live_data" @change="
            handleToggleSettings(
              check_rows_before_transfer_to_live_data,
              'check_rows_before_transfer_to_live_data'
            )
            " />
        <label class="form-check-label" for="check_rows_before_transfer_to_live_data">
          {{
            $t(
              "tools.configurator.import_settings.check_rows_before_transfer_to_live_data"
            )
          }}
        </label>
      </div>
      <div class="form-group">
        <div class="dropdown">
          <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
            @click="toggleDropdown">
            {{ $t("tools.configurator.import_settings.select_statuses") }}
          </button>
          <div class="dropdown-menu" :class="{ show: dropdownOpen }" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" href="#" @click.prevent="selectAll">{{
              $t("select_all")
            }}</a>
            <a class="dropdown-item" href="#" @click.prevent="deselectAll">{{
              $t("deselect_all")
            }}</a>
            <div class="dropdown-divider"></div>
            <div v-for="status in statusesList" :key="status.uuid" class="dropdown-item">
              <input type="checkbox" :id="status.uuid" :value="status.uuid" v-model="selected_statuses"
                @change="handleStatusChange" />
              <label :for="status.uuid">{{ status.name }}</label>
            </div>
          </div>
        </div>
        <div class="selected-statuses">
          <span v-for="status_uuid in selected_statuses" :key="status_uuid" class="badge badge-primary">
            {{
              statusesList.find((status) => status.uuid === status_uuid).name
            }}
            <button type="button" class="close" @click="remove(status_uuid)">
              <span>&times;</span>
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: "ImportValidatorSettings",
  data: function () {
    return {
      selected_template: null,
      parcel_full_identification: false,
      check_rows_before_transfer_to_live_data: false,
      selected_statuses: [],
      dropdownOpen: false,
    };
  },
  props: ["validatorSettings", "statusesList"],
  methods: {
    onSelectedTemplateChange() {
      if (!this.selected_template.settings) {
        this.selected_template.settings = {
          parcel_full_identification: false,
          check_rows_before_transfer_to_live_data: false,
          status_uuids: [],
        };
      }

      this.parcel_full_identification =
        this.selected_template.settings.parcel_full_identification;
      this.check_rows_before_transfer_to_live_data =
        this.selected_template.settings.check_rows_before_transfer_to_live_data;
      this.selected_statuses = this.selected_template?.settings?.status_uuids;
    },
    handleToggleSettings(isActive, setting) {
      this.selected_template.settings[setting] = isActive;
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    handleStatusChange() {
      this.selected_template.settings["status_uuids"] = this.selected_statuses;
    },
    selectAll() {
      this.selected_statuses = this.statusesList.map((status) => status.uuid);
      this.selected_template.settings["status_uuids"] = this.selected_statuses;
    },
    deselectAll() {
      this.selected_statuses = [];
      this.selected_template.settings["status_uuids"] = this.selected_statuses;
    },
    remove(item) {
      const index = this.selected_statuses.indexOf(item);
      if (index >= 0) {
        this.selected_statuses.splice(index, 1);
      }
      this.selected_template.settings["status_uuids"] = this.selected_statuses;
    },
    reset() {
      this.selected_template = null;
      this.selected_statuses = [];
      this.parcel_full_identification = false;
      this.check_rows_before_transfer_to_live_data = false;
    },
  },
  watch: {
    validatorSettings: {
      handler: function (newVal) {
        if (newVal.length > 0 && !this.selected_template) {
          this.selected_template = newVal[0];
          this.onSelectedTemplateChange();
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.validator_settings {
  margin-top: 20px;
  margin-left: 25px;
  margin-bottom: 80px;
}

.form-check {
  margin-bottom: 10px;
}

.dropdown-menu {
  max-height: 350px;
  overflow-y: auto;
}

.selected-statuses {
  margin-top: 10px;
}

.badge {
  margin-right: 5px;
  display: inline-flex;
  align-items: center;
  font-size: 13px;
  color: #606060;
}

.badge .close {
  margin-left: 5px;
  cursor: pointer;
  font-size: 15px;
}
</style>
