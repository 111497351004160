import * as api from '../api/index'
import * as endpoints from '../api/endpoints'
import * as types from "../mutation-types";
import {WORK_LIST_DESKTOP} from "../api/endpoints";
import {fetchFull} from "../api/index";
import {SET_SEASON_ON_PARCEL_HAS_CULTURE} from "../mutation-types";

const actions = {
  getSeasonOnParcelHasCulture({ commit }, payload) {
    return api.fetch(endpoints.SEASON_ON_PARCEL_HAS_CULTURE, payload, commit, (response) => {
      commit(types.SET_SEASON_ON_PARCEL_HAS_CULTURE, response.data);
    });
  },
  getSpcWorkDetails({ commit }, payload) {
    return api.fetch(endpoints.SPC_WORK_DETAILS, payload, commit, (response) => {
      
    });
  },
  createSeasonOnParcelHasCulture({ commit }, payload) {
    return api.post(endpoints.SEASON_ON_PARCEL_HAS_CULTURE, payload, commit, (response) => {
      
    });
  },
  getSpcWorks({ commit }, payload) {
    return api.fetch(payload.endpoint ?? endpoints.MOBILE_SPC_WORKS, payload, commit, (response) => {
      commit(types.SET_SPC_WORKS, response.data);
    });
  },
  nsGetSpcWorksForFilter({ commit }, payload) {
    return api.fetch(endpoints.MOBILE_SPC_WORK_FILTERS, payload, commit);
  },
  getSpcWorkSummary({ commit }, payload) {
    return api.fetch(endpoints.MOBILE_SPC_WORK_SUMMARY, payload, commit, (response) => {
      commit(types.SET_SPC_WORK_SUMMARY, response.data);
    });
  },
  getSpcMachines({ commit }, payload) {
    return api.fetch(endpoints.MOBILE_SPC_MACHINES, payload, commit, (response) => {
      commit(types.SET_SPC_MACHINES, response.data);
    });
  },
  nsGetSpcMachines({ commit }, payload) {
    return api.fetch(endpoints.MOBILE_SPC_MACHINES, payload, commit);
  },
  nsGetSpcMachinesForFilter({ commit }, payload) {
    return api.fetch(endpoints.MOBILE_SPC_MACHINES_FILTER, payload, commit);
  },
  getSpcParcels({ commit }, payload) {
    return api.fetch(endpoints.MOBILE_SPC_PARCELS, payload, commit, (response) => {
      commit(types.SET_SPC_PARCELS, response.data);
    });
  },
  nsGetSpcParcels({ commit }, payload) {
    return api.fetch(endpoints.MOBILE_SPC_PARCELS, payload, commit);
  },
  nsGetSpcParcelsForFilter({ commit }, payload) {
    return api.fetch(endpoints.MOBILE_SPC_PARCEL_FOR_FILTERS, payload, commit);
  },
  getWorkListForDesktop({ commit }, payload) {
    return api.fetchFull(endpoints.WORK_LIST_DESKTOP, payload, commit);
  },
  getParcelsAndSeasons({ commit }, payload) {
    return api.fetchFull(endpoints.PARCELS_FOR_SEASON, payload, commit);
  },
  updateWork({commit}, payload) {
    return api.put(endpoints.WORK_LIST_DESKTOP+'/'+payload.uuid, payload, commit, (response) => {
    })
  },
}

export default actions;
